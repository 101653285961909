<template>
    <div class="page-empty">
        <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
        <p class="des">{{describeText}}</p>
    </div>
</template>

<script>
export default {
    name: 'empty',
    props: {
        describeText: {
            type: String,
            default: '暂无数据',
        }
    },
    components: {
    },
    data () {
        return {
        };
    },
    created () { },
    mounted () { },
    methods: {

    },
}
</script>

<style lang="scss" scope>
.page-empty {
    width: 100%;
    height: 383px;
    background: white;
    border-radius: 10px;
    text-align: center;
    > img {
        width: 158px;
        height: 178px;
        margin: 73px auto 0;
    }
    .des {
        margin: 24px auto 0;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
    }
}
</style>