var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "recommed-comment",
    },
    [
      _c("div", { staticClass: "comment-wrapper" }, [
        _c("div", { staticClass: "comments-wrap" }, [
          _c(
            "div",
            { staticClass: "coment-wrap", attrs: { id: "commentPanelId2" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canEvalute || _vm.canReply,
                      expression: "canEvalute ||canReply",
                    },
                  ],
                },
                [
                  _vm.operationType == "reply"
                    ? _c("div", { staticClass: "reply-tip" }, [
                        _vm._v("\n                        对 "),
                        _c("span", { staticClass: "user" }, [
                          _vm._v(_vm._s(_vm.replyUser)),
                        ]),
                        _vm._v(" 的内容 "),
                        _c("span", { staticClass: "origin-content" }, [
                          _vm._v("“" + _vm._s(_vm.replyContent) + "”"),
                        ]),
                        _vm._v(" 进行回复：\n                    "),
                      ])
                    : _c("p", { staticClass: "score-and-tip" }, [
                        _c(
                          "span",
                          { staticClass: "star-wrap" },
                          _vm._l(_vm.stars, function (star, index) {
                            return _c(
                              "span",
                              {
                                key: "star_" + index,
                                staticClass: "star-item",
                                class: { "disabled-click": _vm.hasEvalute },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateStarRating(index + 1)
                                  },
                                },
                              },
                              [
                                star
                                  ? _c("font-icon", {
                                      staticClass: "star-on",
                                      attrs: {
                                        href: "#icon-a-rencaiyishoucang",
                                      },
                                    })
                                  : _c("font-icon", {
                                      staticClass: "star-off",
                                      attrs: { href: "#icon-shoucang_fill" },
                                    }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm.score
                          ? _c("span", { staticClass: "tip-text" }, [
                              _c("span", { staticClass: "score-text" }, [
                                _vm._v(_vm._s(_vm.score) + "分"),
                              ]),
                              _vm.score == "1"
                                ? _c("i", [_vm._v("非常不匹配，不建议推荐")])
                                : _vm._e(),
                              _vm.score == "2"
                                ? _c("i", [
                                    _vm._v("匹配度较低，推荐需慎重考虑"),
                                  ])
                                : _vm._e(),
                              _vm.score == "3"
                                ? _c("i", [_vm._v("基本匹配，缺乏亮点")])
                                : _vm._e(),
                              _vm.score == "4"
                                ? _c("i", [_vm._v("较为匹配，有一定竞争力")])
                                : _vm._e(),
                              _vm.score == "5"
                                ? _c("i", [
                                    _vm._v("非常匹配，很建议推荐给客户"),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal",
                      attrs: { method: "post", name: "comment" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.add.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "atWrap" }, [
                          _c(
                            "textarea",
                            _vm._b(
                              {
                                staticClass: "evalute-textarea",
                                attrs: {
                                  disabled: _vm.hasEvalute,
                                  rows: "4",
                                  id: "comment",
                                  "data-haha": "123",
                                  name: "comment",
                                  placeholder: "",
                                  "data-maxLength": "",
                                },
                              },
                              "textarea",
                              {
                                "data-isMyJob": _vm.commentObjectInfo.isMyJob
                                  ? 1
                                  : 0,
                              },
                              false
                            )
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "at-tools clearfix",
                              class: { "textarea-disabled": _vm.hasEvalute },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("input", {
                                    attrs: { type: "hidden", name: "privacy" },
                                    domProps: { value: _vm.privacy },
                                  }),
                                  _c(
                                    "el-dropdown",
                                    {
                                      on: { command: _vm.handlePrivacyCommand },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [
                                          _vm.privacy == 0
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "privacy",
                                                  attrs: {
                                                    id: "privacy",
                                                    title: "设置私密性",
                                                    href: "javascript:;",
                                                  },
                                                },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-chakan",
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("公开")]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.privacy == 1
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "privacy",
                                                  attrs: {
                                                    id: "privacy",
                                                    title: "设置私密性",
                                                    href: "javascript:;",
                                                  },
                                                },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-suo1",
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("私密")]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass: "privacy-dropdown-menu",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "light",
                                                content: "所有人能看到评论",
                                                placement: "left",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "0" } },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-chakan",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "公开\n                                                    "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "light",
                                                content:
                                                  "只有被@的人才能看到评论",
                                                placement: "left",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "1" } },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-suo1",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "私密\n                                                    "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "atUserIds",
                                    },
                                  }),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        title: "",
                                        width: "370",
                                        trigger: "click",
                                      },
                                      model: {
                                        value: _vm.atListPopoverVisible,
                                        callback: function ($$v) {
                                          _vm.atListPopoverVisible = $$v
                                        },
                                        expression: "atListPopoverVisible",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "atList",
                                          attrs: {
                                            "aria-labelledby": "atlist",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "search" }, [
                                            _c("input", {
                                              attrs: {
                                                autocomplete: "off",
                                                type: "text",
                                                name: "keyword",
                                                placeholder: "查找更多好友",
                                              },
                                              on: {
                                                keyup: _vm.searchUsers,
                                                keydown: _vm.stopEvent,
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "ul",
                                            [
                                              _vm._l(
                                                _vm.users,
                                                function (user, index) {
                                                  return [
                                                    user.type == 1 &&
                                                    index ==
                                                      _vm.users.length - 2
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "bazaSelectMenuTipsBox",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "小猎或其他用户"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c("li", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectPerson(
                                                                user,
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm._f(
                                                                "avatar"
                                                              )(user.avatar),
                                                              width: "16",
                                                            },
                                                          }),
                                                          user.isFirmTeam
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "team-tip",
                                                                },
                                                                [_vm._v("团队")]
                                                              )
                                                            : _vm._e(),
                                                          _c("span", {
                                                            staticClass:
                                                              "realName",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  user.realName
                                                                ),
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "nickname",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.nickname
                                                                    ? `@${user.nickname}`
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "at-list-icon",
                                          attrs: {
                                            slot: "reference",
                                            id: "atlist",
                                            title: "对TA说",
                                            href: "javascript:;",
                                          },
                                          on: { click: _vm.getClickAtUserInfo },
                                          slot: "reference",
                                        },
                                        [_vm._v("@")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "comment-operation" }, [
                        _c("div", { staticClass: "col-xs-6 email-operation" }, [
                          _c("span", {
                            staticClass: "text-danger hide",
                            attrs: { id: "error" },
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isEmail,
                                  expression: "isEmail",
                                },
                              ],
                              staticClass: "email-checkbox",
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.isEmailNotic,
                                    callback: function ($$v) {
                                      _vm.isEmailNotic = $$v
                                    },
                                    expression: "isEmailNotic",
                                  },
                                },
                                [_vm._v("发送邮件通知")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-xs-6 text-right" }, [
                          _c(
                            "input",
                            _vm._b(
                              {
                                staticClass: "btn btn-primary btn-background",
                                class: { "disabled-click": _vm.hasEvalute },
                                attrs: {
                                  id: "publish",
                                  type: "submit",
                                  value: "发表",
                                  disabled: _vm.hasEvalute,
                                },
                              },
                              "input",
                              { disabled: !_vm.canPost },
                              false
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.evaluateList, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "evaluate-list" },
                  [
                    _c("div", { staticClass: "top" }, [
                      _c(
                        "div",
                        { staticClass: "top-info" },
                        [
                          _c("avatar", {
                            attrs: {
                              userId: item.posterId,
                              src: item.avatarUrl,
                              enableLink: true,
                              enableName: false,
                              realName: item.posterName,
                              nickName: item.posterNickname,
                              isRecommendation: true,
                              shareData: _vm.shareData,
                              size: "md",
                            },
                          }),
                          _c("div", { staticClass: "name-and-score" }, [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(item.posterName) +
                                  "@" +
                                  _vm._s(item.posterNickname)
                              ),
                            ]),
                            _c("p", { staticClass: "star-wrap" }, [
                              _c(
                                "span",
                                { staticClass: "star-item" },
                                [
                                  _vm._l(
                                    _vm.computedParse(item.content, "score"),
                                    function (star, index) {
                                      return _c("font-icon", {
                                        key: "starOn_" + index,
                                        staticClass: "star-on",
                                        attrs: {
                                          href: "#icon-a-rencaiyishoucang",
                                        },
                                      })
                                    }
                                  ),
                                  _vm._l(
                                    5 -
                                      _vm.computedParse(item.content, "score"),
                                    function (star, index) {
                                      return _c("font-icon", {
                                        key: "starOff_" + index,
                                        staticClass: "star-off",
                                        attrs: { href: "#icon-shoucang_fill" },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("span", { staticClass: "score-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.computedParse(item.content, "score")
                                  ) + "分"
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "top-time" }, [
                        item.posterId === _vm.userInfo.id && _vm.canEvalute
                          ? _c(
                              "span",
                              {
                                staticClass: "edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.editEvaluate(item)
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  staticClass: "edit-icon",
                                  attrs: { href: "#icon-bianji" },
                                }),
                                _vm._v("编辑\n                            "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("formatDate")(
                                item.created,
                                "YYYY-MM-DD HH:mm"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottom" }, [
                      item.privacy == 1
                        ? _c(
                            "span",
                            { staticClass: "secret", attrs: { title: "私密" } },
                            [_vm._v("密")]
                          )
                        : _vm._e(),
                      _c("span", {
                        staticClass: "detail-content",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatHtml(
                              _vm.computedParse(item.content, "text"),
                              item.atUsers
                            )
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "operation-bar" }, [
                      _c(
                        "span",
                        {
                          staticClass: "reply",
                          on: {
                            click: function ($event) {
                              return _vm.replyEvaluate(item)
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            staticClass: "reply-icon",
                            attrs: { href: "#icon-xinliweibao-pinglun" },
                          }),
                          _vm._v("\n                        回复"),
                        ],
                        1
                      ),
                      item.replyCount > 0
                        ? _c("div", { staticClass: "toggle" }, [
                            _c("span", { staticClass: "tip-text" }, [
                              _c("span", { staticClass: "count" }, [
                                _vm._v(_vm._s(item.replyCount)),
                              ]),
                              _vm._v(" 条回复\n                            "),
                            ]),
                            _vm.showSpread(item)
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggle(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "展开回复\n                                "
                                    ),
                                    _c("font-icon", {
                                      staticClass: "toggle-icon",
                                      attrs: { href: "#icon-toggle-open" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showFold(item)
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.fold(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "收起回复\n                                "
                                    ),
                                    _c("font-icon", {
                                      staticClass: "toggle-icon",
                                      attrs: { href: "#icon-toggle-close" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.currentComment == item.id && _vm.replyList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "reply-list" },
                          _vm._l(_vm.replyList, function (sItem, index) {
                            return _c(
                              "div",
                              {
                                key: "replay_" + index,
                                staticClass: "reply-cell",
                              },
                              [
                                _c("div", { staticClass: "cell-top" }, [
                                  _c("div", { staticClass: "pic" }, [
                                    _c("img", {
                                      attrs: {
                                        src: sItem.avatarUrl + "?size=60",
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "content-r" }, [
                                    _c("div", { staticClass: "top-line" }, [
                                      _c("div", { staticClass: "top-l" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            _vm._s(sItem.posterName) +
                                              "@" +
                                              _vm._s(sItem.posterNickname)
                                          ),
                                        ]),
                                        _c("span", { staticClass: "time" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                sItem.created,
                                                "YYYY-MM-DD HH:mm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "top-r" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "reply",
                                            on: {
                                              click: function ($event) {
                                                return _vm.replyEvaluate(sItem)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              staticClass: "reply-icon",
                                              attrs: {
                                                href: "#icon-xinliweibao-pinglun",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                            回复"
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.computedReplyConted(sItem)
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v("用户需求"),
                                        _c("span", [_vm._v("@zhougs")]),
                                        _vm._v(" 这个职位推"),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "origin-content" }, [
                                  _c("div", { staticClass: "origin-name" }, [
                                    _vm._v(
                                      _vm._s(sItem.belongCommentPosterName) +
                                        "@" +
                                        _vm._s(
                                          sItem.belongCommentPosterNickName
                                        )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "origin-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("overEllipsis")(
                                          sItem.belongCommentContent.text ||
                                            sItem.belongCommentContent,
                                          30
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                )
              }),
              !_vm.canEvalute && _vm.evaluateList.length == 0
                ? _c("div", { staticClass: "empty" }, [
                    _vm._v(
                      "\n                    无评分数据\n                "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.atNoticeDialogVisible,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.atNoticeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("你只@了自己，只有自己才能收到哦！")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelAtNotice } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmAtNoticeLoading,
                      expression: "confirmAtNoticeLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAtNotice },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确定发表吗？",
            visible: _vm.withoutAtDialogVisible,
            width: "320px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.withoutAtDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("你好像想对别人留言，但没有@任何人。"),
            _c("br"),
            _vm._v("@了别人才能收到消息哦！"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelWithoutAt } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmWithoutAtLoading,
                      expression: "confirmWithoutAtLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmWithoutAt },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }