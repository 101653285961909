var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.recoredsLoading,
          expression: "recoredsLoading",
        },
      ],
      staticClass: "tl-wrap",
    },
    _vm._l(_vm.recoredsList, function (item, index) {
      return _c("div", { key: "timeline_" + index, staticClass: "tl-cell" }, [
        _c("div", { staticClass: "row-item" }, [
          _c("span", { staticClass: "tag", class: _vm.getTagClass(item) }, [
            _vm._v(_vm._s(item.typeText)),
          ]),
        ]),
        _c("div", { staticClass: "row-time" }, [
          _c("div", { staticClass: "txt" }, [
            item.posterRealName == "系统内置用户"
              ? _c("span", {
                  staticClass: "green",
                  attrs: { "data-id": "a83c0366-a433-47ac-b6e8-2db5b96e67b3" },
                  domProps: { textContent: _vm._s(item.posterRealName) },
                })
              : _c("span", {
                  staticClass: "green",
                  attrs: { "data-id": "a83c0366-a433-47ac-b6e8-2db5b96e67b3" },
                  domProps: {
                    textContent: _vm._s(
                      item.posterRealName +
                        (item.posterNickName ? "@" + item.posterNickName : "")
                    ),
                  },
                }),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.genereteHtml(item)) },
            }),
          ]),
          _c("div", {
            staticClass: "time",
            domProps: { textContent: _vm._s(item.created) },
          }),
        ]),
        _c("div", { staticClass: "tl-dot" }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }