<template>
    <a :href="href" target="_blank" class="recommendation-status-tag">
        <el-tag 
            :type="theme"
        >
            <font-icon v-if="theme == 'warning'" href="#icon-weichuli"></font-icon>
            <font-icon v-if="theme == 'error'" href="#icon-yizanting1"></font-icon>
            <font-icon v-if="theme == 'info'" href="#icon-yioffer"></font-icon>
            <font-icon v-if="theme == 'info-purple'" href="#icon-yiwancheng-zise"></font-icon>
            <font-icon v-if="theme == 'default'" href="#icon-yitaotai"></font-icon>
            <font-icon v-if="theme == 'success' && icon == 'clock'" href="#icon-a-bianzu11"></font-icon>
            <font-icon v-if="theme == 'success' && icon == 'yes'" href="#icon-yiwancheng"></font-icon>
            {{text}}
        </el-tag>
    </a>
</template>

<script>
    import recommendationStatus from '#/js/config/recommendation.json';
    import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
    // 根据状态码或者标签英文名查找状态配置
    function findRecommendationStatus (label) {
        if (typeof label == 'number') {
            let target = null;
            Object.keys(recommendationStatus).forEach(key => {
                if(recommendationStatus[key].code === label) {
                    target = recommendationStatus[key];
                }
            })
            return target;
        } else {
            return JSON.parse(JSON.stringify(recommendationStatus[label.toLowerCase()]));
        }
    }

    export default {
        name: "recommendDationStatusTag",
        data() {
            return {
            }
        },
        computed: {
            status() {
                //  [Display(Name = "发票开票中")]
                // InvoiceCreated = 24,
                // [Display(Name = "发票已回款")]
                // FundReceived = 26,
                if(['InvoiceCreated'].includes(this.label)) {
                    return {
                        "text": invoiceSubFlowStatus[this.flowStepName],
                        "theme": "success",
                        "icon": "clock"
                    };
                }
                if(['FundReceived'].includes(this.label)) {
                    return {
                        "text": invoiceSubFlowStatus[this.flowStepName],
                        "theme": "info-purple",
                        "icon": "success"
                    };
                }
                const currentStatus = findRecommendationStatus(this.label) || {};
                if(this.label == 'Interview' && this.interviewConfirmedStatus != 1 && this.interviewConfirmedStatus != 2) {
                    currentStatus.attachment = '待确认';
                }
                return currentStatus;
            },
            text() {
                return this.status ? this.status.text + (this.status.attachment || '') : '';
            },
            theme() {
                return this.status.theme || '';
            },
            icon() {
                return this.status.icon || '';
            }
        },
        props: {
            href: {
                type:String,
                default: 'javascript:void(0);'
            },
            label: String | Number,
            interviewConfirmedStatus: Number,
            flowStepName: {
                type: String,
                default: '',
            }
        }
    }
</script>

<style scoped lang="scss">
.recommendation-status-tag{
    display: inline-flex;
    .el-tag {
        // min-width: 110px;
        // padding: 4px 4px;
        padding: 0 6px;
        // line-height: 24px;
        height: 20px;
        line-height: 18px;
        // border: none;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
        text-align: center;
        background-color: rgba(235,235,235,0.92);;
        color: #999999;

        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 14px;
            margin-right: 2px;
        }
    }
    .el-tag--info{
        border-color: rgba(81,164,255,0.53);;
        background-color: rgba(185,208,255,0.14);
        color: #579BFF;
    }
    .el-tag--success{
        border-color: rgba(56,188,157,0.53);;
        background-color: rgba(56,188,157,0.14);;
        color: #38BC9C;
    }
    .el-tag--warning{
        border-color: #FFDDA8;
        background-color: #FFECC7;
        color: #FF9E21;
    }
    .el-tag--error {
        background-color: rgba(255,75,75,0.1);
        border-color: #FFB6B6;
        color: #FF4B4B;
    }
    .el-tag--primary{
        border-color: $primary;
    }

    .el-tag--info-purple {
        border-color: #A8B6FF;
        background-color: #E2EAFF;
        color: #5560F7;
    }
}

</style>
