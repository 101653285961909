var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "recommendation-status-tag",
      attrs: { href: _vm.href, target: "_blank" },
    },
    [
      _c(
        "el-tag",
        { attrs: { type: _vm.theme } },
        [
          _vm.theme == "warning"
            ? _c("font-icon", { attrs: { href: "#icon-weichuli" } })
            : _vm._e(),
          _vm.theme == "error"
            ? _c("font-icon", { attrs: { href: "#icon-yizanting1" } })
            : _vm._e(),
          _vm.theme == "info"
            ? _c("font-icon", { attrs: { href: "#icon-yioffer" } })
            : _vm._e(),
          _vm.theme == "info-purple"
            ? _c("font-icon", { attrs: { href: "#icon-yiwancheng-zise" } })
            : _vm._e(),
          _vm.theme == "default"
            ? _c("font-icon", { attrs: { href: "#icon-yitaotai" } })
            : _vm._e(),
          _vm.theme == "success" && _vm.icon == "clock"
            ? _c("font-icon", { attrs: { href: "#icon-a-bianzu11" } })
            : _vm._e(),
          _vm.theme == "success" && _vm.icon == "yes"
            ? _c("font-icon", { attrs: { href: "#icon-yiwancheng" } })
            : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }