<template>
    <el-dialog
        class="candidate-similar-resume-dialog"
        width="840px"
        title="相似简历"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="similar-resume-wrapper" id="similarResumeTemplate" ref="similarResumeTemplate">
            <div class="similar-resume">
                <el-alert
                    title="您可能已有该候选人简历"
                    type="info"
                    description="您可以在排除重复后，再决定是否接受本次推荐"
                    show-icon
                    :closable="false">
                </el-alert>
                <!-- <div class="title">
                    <div class="row">
                        <div class="col-2">
                            <i class="text-primary fa fa-exclamation-circle"></i>
                        </div>
                        <div class="col-10 text-light">
                            <p>您可能已有该候选人简历</p>
                            <p>您可以在排除重复后，再决定是否接受本次推荐</p>
                        </div>
                    </div>
                </div> -->
                <div class="similar-resume-list"
                    v-if="isLoading || (!isLoading && list.length)"
                    v-loading="isLoading"
                >
                    <div class="similar-resume-item" v-for="item in list" :key="item.id">
                        <div class="col-6">
                            <div class="pull-left">
                                <!-- <avatar :src="item.headhunterAvatarUrl | avatar" :id="item.headhunterId" :alt="item.headhunterRealName" size="mg" :enableLink="false"></avatar> -->
                                <!-- <avatar
                                    size="mg"
                                    :src="item.headhunterAvatarUrl"
                                    :userId="item.headhunterId"
                                    :enableCard="true"
                                    :enableLink="false"
                                ></avatar> -->
                                <img class="pic" :src="item.headhunterAvatarUrl | avatar" alt="">
                            </div>
                            <div class="detail">
                                <h3 class="title">
                                    <a target="_blank" :href="item.recommendationUrl == '' ? 'javascript:void(0);' : item.recommendationUrl" v-text="item.realName" class="m-r-10"></a>
                                    <recommendation-status-tag :label="item.status"></recommendation-status-tag>
                                </h3>
                                <p><span v-text="item.presentEmployerInfo"></span></p>
                                <p><span v-text="item.degreeInfo"></span></p>
                            </div>
                        </div>
                        <div class="col-3 col-center">
                            <span v-if="item.isMyJob">
                                <!-- <a v-if="item.isHunter" class="text-primary job-name job-name-color" target="_blank" :href="'/Headhunting/MyCompany.html#/Job/' + item.jobId" v-text="item.jobName"></a>
                                <a v-if="!item.isHunter" class="text-primary job-name job-name-color" target="_blank" :href="'/Headhunting/MyCompany.html#/Job/' + item.jobId" v-text="item.jobName"></a> -->
                                <a v-if="item.isHunter" class="text-primary job-name job-name-color" target="_blank" :href="'/#/Job/' + item.jobId" v-text="item.jobName"></a>
                                <a v-if="!item.isHunter" class="text-primary job-name job-name-color" target="_blank" :href="'/#/Job/' + item.jobId" v-text="item.jobName"></a>
                            </span>
                            <span v-if="!item.isMyJob" class="job-name" v-text="item.jobName"></span>
                        </div>
                        <div v-if="item.recommendationUrl != ''" class="col-3 col-center operate">
                            <span v-text="item.updated" class="text-light"></span>
                            <a target="_blank" class="text-primary pull-right" :href="item.recommendationUrl">查看</a>
                        </div>
                    </div>
                </div>
                <div class="empty-data" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>
                <el-pagination
                    class="pagination-common"
                    :current-page="page.current"
                    layout="total, prev, pager, next, slot"
                    :total="page.total"
                    v-if="list.length"
                    @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { candidate as candidateConfig } from '#/js/config/api.json';
import Avatar from '#/component/common/avatar.vue';
import recommendationStatusTag from './recommendation-status-tag/index';

export default {
    props:{
    },
    components:{
        Avatar,
        recommendationStatusTag
    },
    data() {
        return {
            dialogVisible: false,
            recommendationId: '',
            isLoading: false,
            list: [],
            pagerJump: 0,
            page: {
                current: 1,
                size: 10,
                total: 0
            },
        }
    },
    mounted () {
        let _self = this;
    },
    methods: {
        show(id){
            this.dialogVisible = true;
            this.recommendationId = id;
            this.loadData();
        },
        hide(){
            this.modal.hide();
        },
        loadData(isSlient){
            this.isLoading = true;
            if(!isSlient) {
                this.page.current = 1;
            }
            _request({
                url: candidateConfig.url_candidate_similar_resume.replace(/%p/, this.recommendationId),
                method: 'GET',
                data: {
                    start: (this.page.current - 1) * this.page.size,
                    take: this.page.size
                }
           }).then(res => {
                this.page.total = res.total;
                this.list = res.list || [];
           }).finally(() => {
               this.isLoading = false;
           })
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.loadData(true);
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        }
    }
}
</script>

<style lang="scss">
.candidate-similar-resume-dialog {
    .el-dialog__body {
        padding: 20px 25px;
    }

    .similar-resume-list {
        min-height: 100px;
        max-height: 400px;
        overflow-y: scroll;
    }

    .similar-resume-item {
        padding: 20px 0;
        border-bottom: 1px solid #ececec;

        &::after {
            content: '';
            display: block;
            clear: both;
        }

        &:first-child {
            border-top: 0 none;
            border-bottom-style: none;
        }
        .pic{
            width: 58px;
            height: 58px;
            border-radius: 50%;
            overflow: hidden;
        }
        .detail {
            margin-left: 80px;

            h3, p {
                margin-bottom: 0;
            }

            p span {
                display: inline-block;
                max-width: 600px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .recommendation-status-tag .el-tag--success {
            height: 24px;
            line-height: 16px;
        }
    }

    .similar-resume > .title{
        background-color: #FAFAFA;
        color: #999999;
        border: none;

        .fa-exclamation-circle {
            color: #5ecfba;
            font-size: 50px;
            margin: 10px 20px;
        }

        p {
            margin: 10px 0 -10px;
        }
    }
}
.candidate-similar-resume-dialog {
    .empty-data {
        margin: 10px 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 150px;
            height: 150px;
        }
        .empty-text {
            line-height: 20px;
            margin-bottom: 0px;
        }
    }
    .el-pagination.pagination-common {
        padding: 20px 40px;
    }
}
</style>
