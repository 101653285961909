var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-empty" }, [
    _c("img", {
      attrs: { src: require("@src/assets/images/jobList/empty-bg@2x.png") },
    }),
    _c("p", { staticClass: "des" }, [_vm._v(_vm._s(_vm.describeText))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }