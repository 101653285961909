var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "candidate-similar-resume-dialog",
      attrs: {
        width: "840px",
        title: "相似简历",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "similarResumeTemplate",
          staticClass: "similar-resume-wrapper",
          attrs: { id: "similarResumeTemplate" },
        },
        [
          _c(
            "div",
            { staticClass: "similar-resume" },
            [
              _c("el-alert", {
                attrs: {
                  title: "您可能已有该候选人简历",
                  type: "info",
                  description: "您可以在排除重复后，再决定是否接受本次推荐",
                  "show-icon": "",
                  closable: false,
                },
              }),
              _vm.isLoading || (!_vm.isLoading && _vm.list.length)
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      staticClass: "similar-resume-list",
                    },
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "similar-resume-item" },
                        [
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "pull-left" }, [
                              _c("img", {
                                staticClass: "pic",
                                attrs: {
                                  src: _vm._f("avatar")(
                                    item.headhunterAvatarUrl
                                  ),
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "detail" }, [
                              _c(
                                "h3",
                                { staticClass: "title" },
                                [
                                  _c("a", {
                                    staticClass: "m-r-10",
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        item.recommendationUrl == ""
                                          ? "javascript:void(0);"
                                          : item.recommendationUrl,
                                    },
                                    domProps: {
                                      textContent: _vm._s(item.realName),
                                    },
                                  }),
                                  _c("recommendation-status-tag", {
                                    attrs: { label: item.status },
                                  }),
                                ],
                                1
                              ),
                              _c("p", [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      item.presentEmployerInfo
                                    ),
                                  },
                                }),
                              ]),
                              _c("p", [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(item.degreeInfo),
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-3 col-center" }, [
                            item.isMyJob
                              ? _c("span", [
                                  item.isHunter
                                    ? _c("a", {
                                        staticClass:
                                          "text-primary job-name job-name-color",
                                        attrs: {
                                          target: "_blank",
                                          href: "/#/Job/" + item.jobId,
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.jobName),
                                        },
                                      })
                                    : _vm._e(),
                                  !item.isHunter
                                    ? _c("a", {
                                        staticClass:
                                          "text-primary job-name job-name-color",
                                        attrs: {
                                          target: "_blank",
                                          href: "/#/Job/" + item.jobId,
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.jobName),
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            !item.isMyJob
                              ? _c("span", {
                                  staticClass: "job-name",
                                  domProps: {
                                    textContent: _vm._s(item.jobName),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          item.recommendationUrl != ""
                            ? _c(
                                "div",
                                { staticClass: "col-3 col-center operate" },
                                [
                                  _c("span", {
                                    staticClass: "text-light",
                                    domProps: {
                                      textContent: _vm._s(item.updated),
                                    },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-primary pull-right",
                                      attrs: {
                                        target: "_blank",
                                        href: item.recommendationUrl,
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "empty-data" }, [
                    _c("span", { staticClass: "empty-img" }),
                    _c("p", { staticClass: "empty-text" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
              _vm.list.length
                ? _c(
                    "el-pagination",
                    {
                      staticClass: "pagination-common",
                      attrs: {
                        "current-page": _vm.page.current,
                        layout: "total, prev, pager, next, slot",
                        total: _vm.page.total,
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    },
                    [
                      _c("span", { staticClass: "pagination-text" }, [
                        _c(
                          "span",
                          [
                            _vm._v("前往"),
                            _c("el-input", {
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.handlePagerJump.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagerJump,
                                callback: function ($$v) {
                                  _vm.pagerJump = $$v
                                },
                                expression: "pagerJump",
                              },
                            }),
                            _vm._v("页"),
                          ],
                          1
                        ),
                        _c("span", { on: { click: _vm.handlePagerJump } }, [
                          _vm._v("跳转"),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }