var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading && _vm.type !== "recommend",
          expression: "loading && type !== 'recommend'",
        },
      ],
      staticClass: "attachment-lists",
    },
    [
      _vm.attatchmentList.length == 0 && !_vm.loading ? _c("empty") : _vm._e(),
      _vm._l(_vm.attatchmentList, function (item) {
        return _c("attachment-item", {
          key: item.id,
          attrs: {
            data: item,
            send: _vm.send,
            remove: _vm.remove,
            download: _vm.download,
            "candidate-id": _vm.candidateId,
            "resume-id": _vm.resumeId,
            isRecommend: _vm.type == "recommend",
          },
        })
      }),
      _vm.attatchmentList.length > 0 && _vm.hasMore
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.bottomLoading,
                  expression: "bottomLoading",
                },
              ],
              staticClass: "load-more",
              on: { click: _vm.handleLoadMore },
            },
            [
              _vm._v("\n            加载更多\n            "),
              _c("font-icon", { attrs: { href: "#icon-spread-up" } }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }