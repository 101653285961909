var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.candidateRecordLoading,
          expression: "candidateRecordLoading",
        },
      ],
      staticClass: "resume-progress resume-progress-btn",
    },
    [
      (_vm.recommendProcess.isPublisher ||
        _vm.recommendProcess.isRecommender) &&
      [0, 1, 64, 4, 5, 6, 407].includes(_vm.recommendProcess.status)
        ? _c(
            "span",
            { staticClass: "progress-out-tip" },
            [
              _vm.withinDays == "30"
                ? _c("i", [_vm._v("当前推荐流程进行中")])
                : _vm._e(),
              _vm.withinDays == "60"
                ? _c("i", [
                    _vm._v(
                      "如推荐流程长时间未更新，系统将在 " +
                        _vm._s(_vm.expirationDate) +
                        " 时淘汰候选人"
                    ),
                  ])
                : _vm._e(),
              _vm.withinDays == "90"
                ? _c("i", { staticClass: "text-red" }, [
                    _vm._v(
                      "推荐流程长时间未更新，系统将在 " +
                        _vm._s(_vm.expirationDate) +
                        " 天后淘汰候选人，请及时处理"
                    ),
                  ])
                : _vm._e(),
              _vm.withinDays == "30" ||
              _vm.withinDays == "60" ||
              _vm.withinDays == "90"
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "popper-class": "progress-tooltip",
                        effect: "light",
                        content:
                          "未offering的推荐流程，90天未进行更新操作，系统将会淘汰候选人。",
                        placement: "top",
                      },
                    },
                    [
                      _c("font-icon", {
                        staticClass: "tip-icon",
                        attrs: { href: "#icon-warning-circle" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "resume-progress-detail",
          attrs: { id: "resumeProgress" },
        },
        [
          _c(
            "div",
            {
              staticClass: "resume-progress-line clearfix",
              class: [
                "step" +
                  (_vm.recommendProcess.lastStatus ||
                    _vm.getStep(_vm.recommendProcess)),
                { obsolete: _vm.recommendProcess.status === 1024 },
                { "pm-or-hr-page": _vm.isPmOrHrPage },
              ],
            },
            [
              _c("div", {
                staticClass: "extra-tip",
                class: _vm.extraTipClass,
                domProps: { textContent: _vm._s(_vm.extraTip) },
              }),
              _vm._m(0),
              _c(
                "a",
                {
                  staticClass: "pull-left progress-bar step1",
                  attrs: { "data-status": "1" },
                },
                [
                  _c("p", { staticClass: "tip" }, [
                    _vm.recommendProcess.status === 1024
                      ? _c("span", [_vm._v("候选人已淘汰")])
                      : _vm._e(),
                    _vm.recommendProcess.status !== 1024
                      ? _c("span", [_vm._v("已接受")])
                      : _vm._e(),
                  ]),
                  _c("i", { staticClass: "dot" }),
                  _c("p", { staticClass: "line" }),
                  _c("p", [
                    _vm._v("\n                    已接受\n                "),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "pull-left progress-bar step4",
                  attrs: { "data-status": "1" },
                },
                [
                  _c(
                    "p",
                    { staticClass: "tip" },
                    [
                      _vm.recommendProcess.status === 1024
                        ? _c("span", [_vm._v("候选人已淘汰")])
                        : [
                            _vm.recommendProcess.status === 64
                              ? _c("span", [_vm._v("待确认")])
                              : _c("span", [_vm._v("已进入面试阶段")]),
                          ],
                    ],
                    2
                  ),
                  _c("i", { staticClass: "dot" }),
                  _c("p", { staticClass: "line" }),
                  _c("p", [
                    _vm._v("\n                    面试\n                "),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "pull-left progress-bar step7",
                  attrs: { "data-status": "1" },
                },
                [
                  _c("p", { staticClass: "tip" }, [
                    _vm.recommendProcess.status === 1024
                      ? _c("span", [_vm._v("候选人已淘汰")])
                      : _vm._e(),
                    _vm.recommendProcess.status !== 1024
                      ? _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.offeringMap[_vm.recommendProcess.status]
                              ),
                            },
                          },
                          [_vm._v("S1全部面试通过")]
                        )
                      : _vm._e(),
                  ]),
                  _c("i", { staticClass: "dot" }),
                  _c("p", { staticClass: "line" }),
                  _c("p", [
                    _vm._v("\n                    Offering\n                "),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "pull-left progress-bar step8",
                  attrs: { "data-status": "1" },
                },
                [
                  _c("p", { staticClass: "tip" }, [
                    _vm.recommendProcess.status === 1024
                      ? _c("span", [_vm._v("候选人已淘汰")])
                      : _vm._e(),
                    _vm.recommendProcess.status !== 1024
                      ? _c("span", [_vm._v("已offer")])
                      : _vm._e(),
                  ]),
                  _c("i", { staticClass: "dot" }),
                  _c("p", { staticClass: "line" }),
                  _c("p", [
                    _vm._v("\n                    已offer\n                "),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "pull-left progress-bar step16",
                  attrs: { "data-status": "1" },
                },
                [
                  _c("p", { staticClass: "tip" }, [
                    _vm.recommendProcess.status === 1024
                      ? _c("span", [_vm._v("候选人已淘汰")])
                      : _vm._e(),
                    _vm.recommendProcess.status !== 1024
                      ? _c("span", [_vm._v("已入职")])
                      : _vm._e(),
                  ]),
                  _c("i", { staticClass: "dot" }),
                  _c("p", { staticClass: "line" }),
                  _c("p", [
                    _vm._v("\n                    已入职\n                "),
                  ]),
                ]
              ),
              _vm.isPmOrHrPage
                ? _c(
                    "a",
                    {
                      staticClass: "pull-left progress-bar step32",
                      attrs: { "data-status": "1" },
                    },
                    [
                      _vm._m(1),
                      _c("i", { staticClass: "dot" }),
                      _c("p", { staticClass: "line" }),
                      _c("p", [
                        _vm._v(
                          "\n                    已离职\n                "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isPmOrHrPage
                ? _c(
                    "a",
                    {
                      staticClass: "pull-left progress-bar step24",
                      attrs: { "data-status": "1" },
                    },
                    [
                      _vm._m(2),
                      _c("i", { staticClass: "dot" }),
                      _c("p", { staticClass: "line" }),
                      _c("p", [
                        _vm._v(
                          "\n                    开票中\n                "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isPmOrHrPage
                ? _c(
                    "a",
                    {
                      staticClass: "pull-left progress-bar step26",
                      attrs: { "data-status": "1" },
                    },
                    [
                      _vm.recommendProcess.status != "32"
                        ? _c("p", { staticClass: "tip" }, [
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.recommendProcess.status == "32"
                                      ? "已离职"
                                      : "已回款"
                                  ),
                                },
                              },
                              [_vm._v("开票中")]
                            ),
                          ])
                        : _vm._e(),
                      _c("i", { staticClass: "dot" }),
                      _c("p", { staticClass: "line" }),
                      _c(
                        "p",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.recommendProcess.status == "32"
                                ? "离职"
                                : "已回款"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n                    已回款/离职\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._m(3),
            ]
          ),
          _vm.isShowStep
            ? _c("div", [
                _c("div", { staticClass: "step-container" }, [
                  _vm.recommendProcess.status == 0
                    ? _c("div", { staticClass: "step" }, [
                        _vm.recommendProcess.isPublisher
                          ? _c("div", { staticClass: "d-t" }, [
                              _c("div", { staticClass: "d-tbc desc" }, [
                                _c("p", { staticClass: "c-ancillary" }, [
                                  _vm._v(
                                    "\n                                请尽快反馈，您也可以先将简历"
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "t-du opr",
                                      on: { click: _vm.transfer },
                                    },
                                    [_vm._v("转发给面试官")]
                                  ),
                                  _vm._v(
                                    "查看后再反馈！\n                                "
                                  ),
                                  _vm.recommendProcess.hasProjectManager
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: true,
                                              expression: "true",
                                            },
                                          ],
                                          staticClass: "label label-info",
                                        },
                                        [_vm._v("PM已通过")]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm.recommendProcess.hasDuplicateResumes
                                  ? _c(
                                      "p",
                                      { staticClass: "alert alert-danger" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-info-circle text-danger",
                                        }),
                                        _vm._v(
                                          "\n                                发现疑似重复简历\n                                "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "m-l-10 opr text-primary",
                                            on: { click: _vm.showRepeatResume },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("p", { staticClass: "m-t-10" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary m-r-10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.accept("接受")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    接 受\n                                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: { click: _vm.reject },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    拒 绝\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _c(
                              "div",
                              { staticClass: "d-t" },
                              [
                                _c("div", { staticClass: "d-tbc desc v-m" }, [
                                  _c("p", [
                                    _vm._v(
                                      "\n                                等待反馈中，太久了就记得留言催促哦。\n                                "
                                    ),
                                    _vm.recommendProcess.hasProjectManager
                                      ? _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: true,
                                                expression: "true",
                                              },
                                            ],
                                            staticClass: "label label-info",
                                          },
                                          [_vm._v("PM已通过")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm.recommendProcess.isCanRecommendToCustomer
                                  ? _c("add-customer-status", {
                                      ref: "addCustomerStatus",
                                      attrs: {
                                        "is-recommend-to-customer":
                                          _vm.recommendProcess
                                            .isRecommendToCustomer,
                                        candidateStatus:
                                          _vm.recommendProcess
                                            .customerRecomendationViewModel
                                            .candidateStatus,
                                        recommendationStatus:
                                          _vm.recommendProcess
                                            .customerRecomendationViewModel
                                            .recommendationStatus,
                                        "recommend-id": _vm.myrecommendationId,
                                      },
                                      on: {
                                        "retry-recommend":
                                          _vm.handleRetryRecommend,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.recommendProcess.isRecommender &&
                                ![3, 5].includes(_vm.recommendProcess.jobType)
                                  ? _c(
                                      "div",
                                      { staticClass: "d-tbc opr" },
                                      [
                                        _vm.recommendProcess
                                          .isCanRecommendToCustomer
                                          ? _c("add-customer", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.recommendProcess
                                                      .isRecommendToCustomer,
                                                  expression:
                                                    "!recommendProcess.isRecommendToCustomer",
                                                },
                                              ],
                                              ref: "addCustomer",
                                              attrs: {
                                                "recommend-id":
                                                  _vm.myrecommendationId,
                                                "update-recommend-status":
                                                  _vm.handleUpdateRecommendStatus,
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.obsolete(
                                                  _vm.recommendProcess.status
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                淘 汰\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 1
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(4),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stage(4, "进入面试")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                进入面试\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.obsolete(
                                              _vm.recommendProcess.status
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                淘 汰\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 2
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(5),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.recommendProcess.isPublisher
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.accept("重新接受")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                重新接受\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 64
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(6),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    [
                                      _vm.recommendProcess.canConfirmInterview
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-padding",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.stage(
                                                    4,
                                                    "确认进入面试"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    确认进入面试\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.obsolete(1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    淘 汰\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 4 &&
                  _vm.recommendProcess.interviews.length == 0
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(7),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.interview("安排面试")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    安排面试\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.obsolete(40)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    淘 汰\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 4 &&
                  _vm.recommendProcess.interviews.length > 0
                    ? _c(
                        "div",
                        { staticClass: "step" },
                        _vm._l(
                          _vm.recommendProcess.interviews,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-t interview",
                                class: { last: index === _vm.interviewsLast },
                              },
                              [
                                _c("div", { staticClass: "d-tbc desc" }, [
                                  _c("div", { staticClass: "media" }, [
                                    _c("div", { staticClass: "media-left" }, [
                                      _c("div", { staticClass: "canlendar" }, [
                                        _c("div", { staticClass: "month" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("smartMonth")(
                                                item.interviewTime
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("div", { staticClass: "day" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("smartDay")(
                                                item.interviewTime
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "media-body" }, [
                                      _c("input", {
                                        attrs: { type: "hidden", name: "id" },
                                        domProps: { value: item.id },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "clearfix",
                                          class: {
                                            "cancel-able": item.result === 0,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pull-left" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("time")(
                                                    item.interviewTime
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          item.interviewType === 0
                                            ? _c("span", {
                                                staticClass:
                                                  "pull-left address text-overflow",
                                                attrs: {
                                                  title: item.interviewAddress,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.interviewAddress
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          item.interviewType === 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pull-left address text-overflow",
                                                },
                                                [_vm._v("电话面试")]
                                              )
                                            : _vm._e(),
                                          item.result === 0 &&
                                          ((_vm.recommendProcess.jobType != 4 &&
                                            _vm.recommendProcess
                                              .isRecommender) ||
                                            _vm.recommendProcess.isPublisher)
                                            ? _c(
                                                "span",
                                                { staticClass: "opr-btn" },
                                                [_vm._v("取消安排")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("p", { staticClass: "evaluation" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "interview-status",
                                            class:
                                              "interview-status-" + item.result,
                                          },
                                          [
                                            item.result === 0
                                              ? _c("span", [_vm._v("待反馈")])
                                              : _vm._e(),
                                            item.result === 1
                                              ? _c("span", [_vm._v("通 过")])
                                              : _vm._e(),
                                            item.result === 2
                                              ? _c("span", [_vm._v("淘 汰")])
                                              : _vm._e(),
                                            item.result === 3
                                              ? _c("span", [_vm._v("爽 约")])
                                              : _vm._e(),
                                            item.result === 4
                                              ? _c("span", [_vm._v("待考虑")])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              item.evaluation
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm.recommendProcess.isCanRecommendToCustomer
                                  ? _c("add-customer-status", {
                                      ref: "addCustomerStatus",
                                      refInFor: true,
                                      attrs: {
                                        "is-recommend-to-customer":
                                          _vm.recommendProcess
                                            .isRecommendToCustomer,
                                        candidateStatus:
                                          _vm.recommendProcess
                                            .customerRecomendationViewModel
                                            .candidateStatus,
                                        recommendationStatus:
                                          _vm.recommendProcess
                                            .customerRecomendationViewModel
                                            .recommendationStatus,
                                        "recommend-id": _vm.myrecommendationId,
                                      },
                                      on: {
                                        "retry-recommend":
                                          _vm.handleRetryRecommend,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.recommendProcess.isRecommender
                                  ? _c(
                                      "div",
                                      { staticClass: "d-tbc opr" },
                                      [
                                        _vm.recommendProcess
                                          .isCanRecommendToCustomer
                                          ? _c("add-customer", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.recommendProcess
                                                      .isRecommendToCustomer,
                                                  expression:
                                                    "!recommendProcess.isRecommendToCustomer",
                                                },
                                              ],
                                              ref: "addCustomer",
                                              refInFor: true,
                                              attrs: {
                                                "recommend-id":
                                                  _vm.myrecommendationId,
                                                "update-recommend-status":
                                                  _vm.handleUpdateRecommendStatus,
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.interview("安排面试")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                安排面试\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.obsolete(42)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                淘 汰\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.recommendProcess.status == 5 ||
                  _vm.recommendProcess.status == 6
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm.recommendProcess.interviews.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.recommendProcess.interviews,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "d-t interview",
                                          class: {
                                            last: index === _vm.interviewsLast,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-tbc desc" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "media-left",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "canlendar",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "month",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "smartMonth"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "day",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "smartDay"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "media-body",
                                                    },
                                                    [
                                                      _c("input", {
                                                        attrs: {
                                                          type: "hidden",
                                                          name: "id",
                                                        },
                                                        domProps: {
                                                          value: item.id,
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "clearfix",
                                                          class: {
                                                            "cancel-able":
                                                              item.result === 0,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pull-left",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "time"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          item.interviewType ===
                                                          0
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "pull-left address text-overflow",
                                                                attrs: {
                                                                  title:
                                                                    item.interviewAddress,
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      item.interviewAddress
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          item.interviewType ===
                                                          1
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pull-left address text-overflow",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "电话面试"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.result === 0 &&
                                                          ((_vm.recommendProcess
                                                            .jobType != 4 &&
                                                            _vm.recommendProcess
                                                              .isRecommender) ||
                                                            _vm.recommendProcess
                                                              .isPublisher)
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "opr-btn",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancel(
                                                                          item.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "取消安排"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "evaluation",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "interview-status",
                                                              class:
                                                                "interview-status-" +
                                                                item.result,
                                                            },
                                                            [
                                                              item.result === 0
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "待反馈"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 1
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "通 过"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 2
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "淘 汰"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 3
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "爽 约"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 4
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "待考虑"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c("span", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.evaluation
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.recommendProcess.isMarketRecommendation
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary btn-padding",
                                            on: { click: _vm.process },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                反馈面试结果\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 407
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm.recommendProcess.interviews.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.recommendProcess.interviews,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "d-t interview",
                                          class: {
                                            last: index === _vm.interviewsLast,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-tbc desc" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "media-left",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "canlendar",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "month",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "smartMonth"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "day",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "smartDay"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "media-body",
                                                    },
                                                    [
                                                      _c("input", {
                                                        attrs: {
                                                          type: "hidden",
                                                          name: "id",
                                                        },
                                                        domProps: {
                                                          value: item.id,
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "clearfix",
                                                          class: {
                                                            "cancel-able":
                                                              item.result === 0,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pull-left",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "time"
                                                                  )(
                                                                    item.interviewTime
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          item.interviewType ===
                                                          0
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "pull-left address text-overflow",
                                                                attrs: {
                                                                  title:
                                                                    item.interviewAddress,
                                                                },
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      item.interviewAddress
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          item.interviewType ===
                                                          1
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pull-left address text-overflow",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "电话面试"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.result === 0 &&
                                                          ((_vm.recommendProcess
                                                            .jobType != 4 &&
                                                            _vm.recommendProcess
                                                              .isRecommender) ||
                                                            _vm.recommendProcess
                                                              .isPublisher)
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pull-left opr",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancel(
                                                                          item.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-times c-orange m-l-20",
                                                                  }),
                                                                  _vm._v(
                                                                    " 取消\n                                                "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "evaluation",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "interview-status",
                                                              class:
                                                                "interview-status-" +
                                                                item.result,
                                                            },
                                                            [
                                                              item.result === 0
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "待反馈"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 1
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "通 过"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 2
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "淘 汰"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 3
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "爽 约"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.result === 4
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "待考虑"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c("span", {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.evaluation
                                                                ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.interview("安排下一轮")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                安排下一轮\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-padding",
                                        on: {
                                          click: function ($event) {
                                            return _vm.enterOffering(
                                              407,
                                              "进入Offering中"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                进入Offering中\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        staticStyle: { "min-width": "60px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.obsolete(4)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                淘 汰\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  [7, 9, 10, 11, 12, 13].includes(_vm.recommendProcess.status)
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(8),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    [7, 9, 10, 11, 12].includes(
                                      _vm.recommendProcess.status
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: { click: _vm.offerNext },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                下一步\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.recommendProcess.isMarketRecommendation
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: { click: _vm.offer },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                offer\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.obsolete(
                                              _vm.recommendProcess.status
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                淘 汰\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  [8].includes(_vm.recommendProcess.status)
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _vm._m(9),
                            _vm.recommendProcess.isCanRecommendToCustomer
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.recommendProcess
                                      .isMarketRecommendation &&
                                    _vm.recommendProcess.canCreateOfferInfo
                                      ? _c(
                                          "i",
                                          {
                                            staticClass: "text-orange",
                                            staticStyle: {
                                              "margin-right": "5px",
                                            },
                                          },
                                          [_vm._v("*猎头未填写业绩分成")]
                                        )
                                      : _vm._e(),
                                    !_vm.recommendProcess
                                      .isMarketRecommendation &&
                                    !_vm.recommendProcess.canCreateOfferInfo
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.stage(16, "入职")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                入 职\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.obsolete(
                                              _vm.recommendProcess.status
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                淘 汰\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  [16, 24, 26].includes(_vm.recommendProcess.status)
                    ? _c("div", { staticClass: "step" }, [
                        _c(
                          "div",
                          { staticClass: "d-t" },
                          [
                            _c("div", { staticClass: "d-tbc desc" }, [
                              _c("div", { staticClass: "media m-t-0" }, [
                                _vm._m(10),
                                _vm.recommendProcess.status == 16
                                  ? _c("div", { staticClass: "media-body" }, [
                                      _c("p", [
                                        _vm._v(
                                          "\n                                        已入职\n                                    "
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                        恭喜恭喜！还继续招人吗？别忘了继续打理您的职位！\n                                    "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm.recommendProcess.isCanRecommendToCustomer &&
                            _vm.recommendProcess.status == 16
                              ? _c("add-customer-status", {
                                  ref: "addCustomerStatus",
                                  attrs: {
                                    "is-recommend-to-customer":
                                      _vm.recommendProcess
                                        .isRecommendToCustomer,
                                    candidateStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .candidateStatus,
                                    recommendationStatus:
                                      _vm.recommendProcess
                                        .customerRecomendationViewModel
                                        .recommendationStatus,
                                    "recommend-id": _vm.myrecommendationId,
                                  },
                                  on: {
                                    "retry-recommend": _vm.handleRetryRecommend,
                                  },
                                })
                              : _vm._e(),
                            _vm.recommendProcess.isRecommender ||
                            _vm.recommendProcess.isPublisher
                              ? _c(
                                  "div",
                                  { staticClass: "d-tbc opr" },
                                  [
                                    _vm.recommendProcess
                                      .isCanRecommendToCustomer &&
                                    _vm.recommendProcess.status == 16
                                      ? _c("add-customer", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.recommendProcess
                                                  .isRecommendToCustomer,
                                              expression:
                                                "!recommendProcess.isRecommendToCustomer",
                                            },
                                          ],
                                          ref: "addCustomer",
                                          attrs: {
                                            "recommend-id":
                                              _vm.myrecommendationId,
                                            "update-recommend-status":
                                              _vm.handleUpdateRecommendStatus,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stage(32, "离职")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                离 职\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.recommendProcess.status == 32
                    ? _c("div", { staticClass: "step" }, [_vm._m(11)])
                    : _vm._e(),
                  _vm.recommendProcess.status == 1024
                    ? _c("div", { staticClass: "step" }, [
                        _c("div", { staticClass: "d-t" }, [
                          _c("div", { staticClass: "d-tbc desc" }, [
                            _c("div", { staticClass: "media m-t-0" }, [
                              _vm._m(12),
                              _c("div", { staticClass: "media-body" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                                        候选人已淘汰："
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.recommendProcess.obsoleteReason
                                      ),
                                    },
                                  }),
                                ]),
                                _c("p", [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.recommendProcess.obsoleteDetail ||
                                          "无"
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm.recommendProcess.isRecommender ||
                          _vm.recommendProcess.isPublisher
                            ? _c(
                                "div",
                                { staticClass: "d-tbc opr" },
                                [
                                  _vm.recommendProcess.isCanRecommendToCustomer
                                    ? _c("add-customer", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.recommendProcess
                                                .isRecommendToCustomer,
                                            expression:
                                              "!recommendProcess.isRecommendToCustomer",
                                          },
                                        ],
                                        ref: "addCustomer",
                                        attrs: {
                                          "recommend-id":
                                            _vm.myrecommendationId,
                                          "update-recommend-status":
                                            _vm.handleUpdateRecommendStatus,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.stage(1024, "恢复")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                恢 复\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _c("stageDialog", {
            ref: "stageDialog",
            attrs: { status: _vm.stageStatus },
            on: {
              "refresh-lock-info": () => {
                this.$emit("refresh-lock-info")
              },
              successFn: _vm.SuccessRefresh,
            },
          }),
          _c("obsoleteDialog", {
            ref: "obsoleteDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
          _c("interviewDialog", {
            ref: "interviewDialog",
            on: { successFn: _vm.SuccessInterviewRefresh },
          }),
          _c("acceptDialog", {
            ref: "acceptDialog",
            on: { successFn: _vm.acceptSuccessRefresh },
          }),
          _c("rejectDialog", {
            ref: "rejectDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
          _c("offerDialog", {
            ref: "offerDialog",
            staticClass: "recommend-offer-vue",
            on: {
              successFn: _vm.offerSuccessRefresh,
              successEditOffer: function ($event) {
                return _vm.$emit("successEditOffer")
              },
            },
          }),
          _c("processDialog", {
            ref: "processDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
          _c("cancelDialog", {
            ref: "cancelDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
          _c("approveDialog", {
            ref: "approveDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
          _c("enter-sure-interview-dialog", {
            ref: "enterSureInterviewDialog",
            on: { successFn: _vm.SuccessRefresh },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "pull-left progress-bar step empty first",
        attrs: { "data-status": "1" },
      },
      [
        _c("p", { staticClass: "tip" }, [
          _vm._v("\n                    空\n                "),
        ]),
        _c("i", { staticClass: "dot" }),
        _c("p", { staticClass: "line" }),
        _c("p"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tip" }, [_c("span", [_vm._v("已离职")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tip" }, [_c("span", [_vm._v("开票中")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "pull-left progress-bar step empty last",
        attrs: { "data-status": "1" },
      },
      [
        _c("p", { staticClass: "tip" }, [
          _vm._v("\n                    空\n                "),
        ]),
        _c("i", { staticClass: "dot" }),
        _c("p", { staticClass: "line" }),
        _c("p"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("p", { staticClass: "c-ancillary" }, [
        _c("i", { staticClass: "fa fa-calendar-check-o m-r-5" }),
        _vm._v(
          "人才难抢，还等什么，赶快推进到面试阶段吧！\n                            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("p", [
        _c("i", { staticClass: "fa fa-calendar-times-o f-30 m-r-20 v-m" }),
        _vm._v("候选人已被拒绝\n                            "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("p", [
        _c("i", { staticClass: "fa fa-calendar-check-o" }),
        _vm._v("推荐顾问和发布顾问都点击进入面试才可安排面试哦"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("p", [
        _c("i", { staticClass: "fa fa-calendar-check-o" }),
        _vm._v("请您协调好面试时间，在线安排面试。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("div", { staticClass: "media m-t-0" }, [
        _c("div", { staticClass: "media-left" }, [
          _c("i", { staticClass: "text-success fa fa-gift f-30 m-r-20" }),
        ]),
        _c("div", { staticClass: "media-body" }, [
          _c("p", [
            _vm._v(
              "\n                                        offer中\n                                    "
            ),
          ]),
          _c("p", [
            _vm._v(
              "\n                                        马上就能根候选人发offer啦，拜托推荐人看紧哦。\n                                    "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-tbc desc" }, [
      _c("div", { staticClass: "media m-t-0" }, [
        _c("div", { staticClass: "media-left" }, [
          _c("i", { staticClass: "text-success fa fa-gift f-30 m-r-20" }),
        ]),
        _c("div", { staticClass: "media-body" }, [
          _c("p", [
            _vm._v(
              "\n                                        已offer\n                                    "
            ),
          ]),
          _c("p", [
            _vm._v(
              "\n                                        正在等待候选人入职，拜托推荐人看紧哦。\n                                    "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-left" }, [
      _c("i", { staticClass: "text-success fa fa-briefcase f-30 m-r-20" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-t" }, [
      _c("div", { staticClass: "d-tbc desc" }, [
        _c("p", { staticClass: "title" }, [
          _c("i", {
            staticClass: "text-danger fa fa-times-circle f-30 m-r-20 v-m",
          }),
          _vm._v("候选人已离职！\n                            "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-left" }, [
      _c("i", {
        staticClass: "text-danger fa fa-times-circle f-30 m-r-20 v-m",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }