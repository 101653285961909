<template>
    <div class="resume-progress resume-progress-btn" v-loading="candidateRecordLoading">
        <!-- <job-reward-dialog ref="jobrewarddialog"></job-reward-dialog> -->
        <!-- <font-icon class="candidate-icon" href="#icon-candidate_statues"></font-icon>
        <span class="resume-progress-title">当前状态</span> -->
        <span class="progress-out-tip" v-if="(recommendProcess.isPublisher || recommendProcess.isRecommender) && [0, 1, 64, 4, 5, 6, 407].includes(recommendProcess.status)">
            <i v-if="withinDays == '30'">当前推荐流程进行中</i>
            <i v-if="withinDays == '60'">如推荐流程长时间未更新，系统将在 {{ expirationDate }} 时淘汰候选人</i>
            <i v-if="withinDays == '90'" class="text-red">推荐流程长时间未更新，系统将在 {{ expirationDate }} 天后淘汰候选人，请及时处理</i>
            <el-tooltip v-if="withinDays == '30' || withinDays == '60' || withinDays == '90'" popper-class="progress-tooltip" effect="light" content="未offering的推荐流程，90天未进行更新操作，系统将会淘汰候选人。" placement="top">
                <font-icon class="tip-icon" href="#icon-warning-circle"></font-icon>
            </el-tooltip>
        </span>
        <div class="resume-progress-detail" id="resumeProgress">
            <!-- 推荐进度流程 -->
            <div 
                class="resume-progress-line clearfix" 
                :class="['step' + (recommendProcess.lastStatus ||getStep(recommendProcess)), 
                    {'obsolete' : recommendProcess.status === 1024},
                    {'pm-or-hr-page': isPmOrHrPage}]">
                <div class="extra-tip" v-text="extraTip" :class="extraTipClass">
                </div>
                <a data-status="1" class="pull-left progress-bar step empty first">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step1">
                    <p class="tip">
                        <span v-if="recommendProcess.status === 1024">候选人已淘汰</span>
                        <span v-if="recommendProcess.status !== 1024">已接受</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已接受
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step4">
                    <p class="tip">
                        <span v-if="recommendProcess.status === 1024">候选人已淘汰</span>
                        <template v-else>
                            <span v-if="recommendProcess.status === 64">待确认</span>
                            <span v-else>已进入面试阶段</span>
                        </template>
                        
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        面试
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step7">
                    <p class="tip">
                        <span v-if="recommendProcess.status === 1024">候选人已淘汰</span>
                        <span 
                            v-if="recommendProcess.status !== 1024"
                            v-text="offeringMap[recommendProcess.status]"
                        >S1全部面试通过</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        Offering
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step8">
                    <p class="tip">
                        <span v-if="recommendProcess.status === 1024">候选人已淘汰</span>
                        <span v-if="recommendProcess.status !== 1024">已offer</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已offer
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step16">
                    <p class="tip">
                        <span v-if="recommendProcess.status === 1024">候选人已淘汰</span>
                        <span v-if="recommendProcess.status !== 1024">已入职</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已入职
                    </p>
                </a>
                <a v-if="isPmOrHrPage" data-status="1" class="pull-left progress-bar step32">
                    <p class="tip">
                        <span>已离职</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已离职
                    </p>
                </a>
                <!-- <a data-status="1" class="pull-left progress-bar step empty last">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a> -->

                <!-- <a data-status="1" class="pull-left progress-bar step empty first">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step1">
                    <p class="tip">
                        <span>已入职</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已入职
                    </p>
                </a> -->
                <!-- <a v-if="!isPmOrHrPage && !(recommendProcess.status == '32' && recommendProcess.status != '24')" data-status="1" class="pull-left progress-bar step24"> -->
                <a v-if="!isPmOrHrPage" data-status="1" class="pull-left progress-bar step24">
                    <p class="tip">
                        <span>开票中</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        开票中
                    </p>
                </a>
                <a v-if="!isPmOrHrPage" data-status="1" class="pull-left progress-bar step26">
                    <p class="tip" v-if="recommendProcess.status != '32'">
                        <!-- <span v-text="recommendProcess.status == '32' ? '已离职': '已回款'">开票中</span> -->
                        <span v-text="recommendProcess.status == '32' ? '已离职': '已回款'">开票中</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p v-text="recommendProcess.status == '32' ? '离职': '已回款'">
                        已回款/离职
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step empty last">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a>
            </div>
            <!-- 推荐进度流程 -->

            <!-- 入职后的状态 -->
            <!-- 
                [Display(Name = "已入职")]
                Onboard = 16,
                [Display(Name = "发票开票中")]
                InvoiceCreated = 24,
                [Display(Name = "发票已回款")]
                FundReceived = 26,
                [Display(Name = "已离职")]
                Dimission = 32, 
                -->
            <!-- <div 
                v-if="[16, 24, 26, 32].includes(recommendProcess.status) && !isPmOrHrPage"
                class="resume-progress-line clearfix " 
                :class="{
                    'step1': [16].includes(recommendProcess.status),
                    'step4': [24].includes(recommendProcess.status),
                    'step8': [26, 32].includes(recommendProcess.status),
                    }"
                >
                <a data-status="1" class="pull-left progress-bar step empty first">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step1">
                    <p class="tip">
                        <span>已入职</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        已入职
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step4">
                    <p class="tip">
                        <span>开票中</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                        开票中
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step8">
                    <p class="tip">
                        <span v-text="recommendProcess.status == '32' ? '已离职': '已回款'">开票中</span>
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p v-text="recommendProcess.status == '32' ? '离职': '已回款'">
                        已回款/离职
                    </p>
                </a>
                <a data-status="1" class="pull-left progress-bar step empty last">
                    <p class="tip">
                        空
                    </p>
                    <i class="dot"></i>
                    <p class="line"></p>
                    <p>
                    </p>
                </a>
            </div> -->

            <div v-if="isShowStep">
                <div class="step-container">
                    <!-- 待反馈 -->
                    <div class="step" v-if="recommendProcess.status == 0">
                        <div class="d-t" v-if="recommendProcess.isPublisher">
                            <div class="d-tbc desc">
                                <p class="c-ancillary">
                                    请尽快反馈，您也可以先将简历<a class="t-du opr" @click="transfer">转发给面试官</a>查看后再反馈！
                                    <span v-if="recommendProcess.hasProjectManager" class="label label-info" v-show="true">PM已通过</span>
                                </p>
                                <p v-if="recommendProcess.hasDuplicateResumes" class="alert alert-danger">
                                    <i class="fa fa-info-circle text-danger"></i>
                                    发现疑似重复简历
                                    <a @click="showRepeatResume" class="m-l-10 opr text-primary">查看</a>
                                </p>
                                <p class="m-t-10">
                                    <button class="btn btn-primary m-r-10" @click="accept('接受')">
                                        接 受
                                    </button>
                                    <button class="btn btn-danger" @click="reject">
                                        拒 绝
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="d-t" v-else>
                            <div class="d-tbc desc v-m">
                                <p>
                                    等待反馈中，太久了就记得留言催促哦。
                                    <span v-if="recommendProcess.hasProjectManager" class="label label-info" v-show="true">PM已通过</span>
                                </p>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender && ![3, 5].includes(recommendProcess.jobType)">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-danger" @click="obsolete(recommendProcess.status)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 待反馈end -->

                    <!-- 已反馈待面试 -->
                    <div class="step" v-if="recommendProcess.status == 1">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <p class="c-ancillary">
                                    <i class="fa fa-calendar-check-o m-r-5"></i>人才难抢，还等什么，赶快推进到面试阶段吧！
                                </p>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <!-- <button v-if="recommendProcess.canOnekeyOffer" class="btn btn-primary" @click="oneKeyOffer">
                                    一键offer
                                </button> -->
                                <button class="btn btn-primary" @click="stage(4, '进入面试')">
                                    进入面试
                                </button>
                                <button class="btn btn-danger" @click="obsolete(recommendProcess.status)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 已反馈待面试end -->
                    
                    <!-- 重新接受 -->
                    <div class="step" v-if="recommendProcess.status == 2">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <p>
                                    <i class="fa fa-calendar-times-o f-30 m-r-20 v-m"></i>候选人已被拒绝
                                </p>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-primary" @click="accept('重新接受')" v-if="recommendProcess.isPublisher">
                                    重新接受
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 重新接受end -->

                    <!-- 确认进入面试 -->
                    <div class="step" v-if="recommendProcess.status == 64">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <p><i class="fa fa-calendar-check-o"></i>推荐顾问和发布顾问都点击进入面试才可安排面试哦</p>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <template>
                                    <button 
                                        v-if="recommendProcess.canConfirmInterview" 
                                        class="btn btn-primary btn-padding" 
                                        @click="stage(4, '确认进入面试')"
                                    >
                                        确认进入面试
                                    </button>
                                    <button class="btn btn-danger" @click="obsolete(1)">
                                        淘 汰
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 确认进入面试end -->
                    
                    <!-- 面试待安排 -->
                    <div class="step" v-if="recommendProcess.status == 4 && recommendProcess.interviews.length == 0">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <p><i class="fa fa-calendar-check-o"></i>请您协调好面试时间，在线安排面试。</p>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <template>
                                    <!-- <button v-if="recommendProcess.canOnekeyOffer" class="btn btn-primary" @click="oneKeyOffer">
                                        一键offer
                                    </button> -->
                                    <button class="btn btn-primary" @click="interview('安排面试')">
                                        安排面试
                                    </button>
                                    <button class="btn btn-danger" @click="obsolete(40)">
                                        淘 汰
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 面试待安排end -->
                    
                    <div class="step" v-if="recommendProcess.status == 4 && recommendProcess.interviews.length > 0">
                        <div v-for="(item,index) in recommendProcess.interviews" :key="index" class="d-t interview" :class="{ last : index === interviewsLast}" >
                            <div class="d-tbc desc">
                                <div class="media">
                                    <div class="media-left">
                                        <div class="canlendar">
                                            <div class="month" >{{item.interviewTime | smartMonth}}</div>
                                            <div class="day" >{{item.interviewTime | smartDay}}</div>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <input type="hidden" name="id" :value="item.id" />
                                        <p class="clearfix" :class="{ 'cancel-able' : item.result === 0}">
                                            <span class="pull-left" >{{item.interviewTime | time}}</span>
                                            <span v-if="item.interviewType === 0" class="pull-left address text-overflow" v-text="item.interviewAddress" :title="item.interviewAddress"></span>
                                            <span v-if="item.interviewType === 1" class="pull-left address text-overflow">电话面试</span>
                                            <!-- <span v-if="item.result === 0 && ((recommendProcess.jobType != 4 && recommendProcess.isRecommender) || recommendProcess.isPublisher)" class="pull-left opr" style="margin-left:10px;" @click="cancel(item.id)">
                                                <i class="fa fa-times c-orange m-l-20"></i> 取消
                                            </span> -->
                                            <span class="opr-btn" v-if="item.result === 0 && ((recommendProcess.jobType != 4 && recommendProcess.isRecommender) || recommendProcess.isPublisher)">取消安排</span>
                                        </p>
                                        <p class="evaluation">
                                                    <span class="interview-status" :class="'interview-status-' + item.result" >
                                                        <span v-if="item.result === 0">待反馈</span>
                                                        <span v-if="item.result === 1">通 过</span>
                                                        <span v-if="item.result === 2">淘 汰</span>
                                                        <span v-if="item.result === 3">爽 约</span>
                                                        <span v-if="item.result === 4">待考虑</span>
                                                    </span>
                                            <span v-text="item.evaluation"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-primary" @click="interview('安排面试')">
                                    安排面试
                                </button>
                                <button class="btn btn-danger" @click="obsolete(42)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- 面试待评估 -->
                    <div class="step" v-if="recommendProcess.status == 5 || recommendProcess.status == 6">
                        <div class="d-t">
                            <!-- <div class="d-tbc desc">
                                <p>
                                    <i class="fa fa-calendar-check-o"></i>
                                    面试待评估
                                </p>
                            </div> -->
                            <div v-if="recommendProcess.interviews.length > 0" >
                                <div v-for="(item,index) in recommendProcess.interviews" :key="index" class="d-t interview" :class="{ last : index === interviewsLast}" >
                                    <div class="d-tbc desc">
                                        <div class="media">
                                            <div class="media-left">
                                                <div class="canlendar">
                                                    <div class="month" >{{item.interviewTime | smartMonth}}</div>
                                                    <div class="day" >{{item.interviewTime | smartDay}}</div>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <input type="hidden" name="id" :value="item.id" />
                                                <p class="clearfix" :class="{ 'cancel-able' : item.result === 0}">
                                                    <span class="pull-left" >{{item.interviewTime | time}}</span>
                                                    <span v-if="item.interviewType === 0" class="pull-left address text-overflow" v-text="item.interviewAddress" :title="item.interviewAddress"></span>
                                                    <span v-if="item.interviewType === 1" class="pull-left address text-overflow">电话面试</span>
                                                    <span class="opr-btn" v-if="item.result === 0 && (recommendProcess.jobType != 4 && recommendProcess.isRecommender || recommendProcess.isPublisher)" @click="cancel(item.id)">取消安排</span>
                                                </p>
                                                <p class="evaluation">
                                                            <span class="interview-status" :class="'interview-status-' + item.result" >
                                                                <span v-if="item.result === 0">待反馈</span>
                                                                <span v-if="item.result === 1">通 过</span>
                                                                <span v-if="item.result === 2">淘 汰</span>
                                                                <span v-if="item.result === 3">爽 约</span>
                                                                <span v-if="item.result === 4">待考虑</span>
                                                            </span>
                                                    <span v-text="item.evaluation"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <!-- <button v-if="recommendProcess.canOnekeyOffer" class="btn btn-primary" @click="oneKeyOffer">
                                    一键offer
                                </button> -->
                                <button class="btn btn-primary btn-padding" @click="process" v-if="!recommendProcess.isMarketRecommendation">
                                    反馈面试结果
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 面试待评估 end -->

                    <!-- 面试已反馈 -->
                     <!-- || recommendProcess.status == 7 -->
                    <div class="step" v-if="recommendProcess.status == 407">
                        <div class="d-t">
                            <!-- <div class="d-tbc desc">
                                <p>
                                    <i class="fa fa-calendar-check-o"></i>
                                    面试已反馈
                                </p>
                            </div> -->
                            <div v-if="recommendProcess.interviews.length > 0" >
                                <div v-for="(item,index) in recommendProcess.interviews" :key="index" class="d-t interview" :class="{ last : index === interviewsLast}" >
                                    <div class="d-tbc desc">
                                        <div class="media">
                                            <div class="media-left">
                                                <div class="canlendar">
                                                    <div class="month" >{{item.interviewTime | smartMonth}}</div>
                                                    <div class="day" >{{item.interviewTime | smartDay}}</div>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <input type="hidden" name="id" :value="item.id" />
                                                <p class="clearfix" :class="{ 'cancel-able' : item.result === 0}">
                                                    <span class="pull-left" >{{item.interviewTime | time}}</span>
                                                    <span v-if="item.interviewType === 0" class="pull-left address text-overflow" v-text="item.interviewAddress" :title="item.interviewAddress"></span>
                                                    <span v-if="item.interviewType === 1" class="pull-left address text-overflow">电话面试</span>
                                                    <span v-if="item.result === 0 && (recommendProcess.jobType != 4 && recommendProcess.isRecommender || recommendProcess.isPublisher)" class="pull-left opr" @click="cancel(item.id)">
                                                        <i class="fa fa-times c-orange m-l-20"></i> 取消
                                                    </span>
                                                </p>
                                                <p class="evaluation">
                                                            <span class="interview-status" :class="'interview-status-' + item.result" >
                                                                <span v-if="item.result === 0">待反馈</span>
                                                                <span v-if="item.result === 1">通 过</span>
                                                                <span v-if="item.result === 2">淘 汰</span>
                                                                <span v-if="item.result === 3">爽 约</span>
                                                                <span v-if="item.result === 4">待考虑</span>
                                                            </span>
                                                    <span v-text="item.evaluation"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-primary" @click="interview('安排下一轮')">
                                    安排下一轮
                                </button>
                                <!-- <button class="btn btn-primary" @click="offer" v-if="!recommendProcess.isMarketRecommendation && recommendProcess.jobType != 3">
                                    offer
                                </button> -->
                                <!-- <span class="text-green" @click="stage(recommend, 407, '进入Offering中')">进入Offering中</span> -->
                                <button class="btn btn-primary btn-padding" @click="enterOffering(407, '进入Offering中')">
                                    进入Offering中
                                </button>
                                <button class="btn btn-danger" style="min-width: 60px;" @click="obsolete(4)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 面试已反馈 end -->

                    <!-- offering -->
                    <div class="step" v-if="[7, 9, 10, 11, 12, 13].includes(recommendProcess.status)">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <div class="media m-t-0">
                                    <div class="media-left">
                                        <i class="text-success fa fa-gift f-30 m-r-20"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>
                                            offer中
                                        </p>
                                        <p>
                                            马上就能根候选人发offer啦，拜托推荐人看紧哦。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <!-- <i 
                                    class="text-orange" 
                                    style="margin-right:5px;" 
                                    v-if="[3, 5].includes(recommendProcess.jobType)"
                                    >*猎头尚未确认offer</i> -->
                                <button class="btn btn-primary"
                                    v-if="[7, 9, 10, 11, 12].includes(recommendProcess.status)"
                                    @click="offerNext">
                                    下一步
                                </button>
                                 <!-- && ![3, 5].includes(recommendProcess.jobType) -->
                                <button class="btn btn-primary" @click="offer" v-if="!recommendProcess.isMarketRecommendation">
                                    offer
                                </button>
                                <button class="btn btn-danger" @click="obsolete(recommendProcess.status)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- offering end -->

                    <!-- 已offer start -->
                    <div class="step" v-if="[8].includes(recommendProcess.status)">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <div class="media m-t-0">
                                    <div class="media-left">
                                        <i class="text-success fa fa-gift f-30 m-r-20"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>
                                            已offer
                                        </p>
                                        <p>
                                            正在等待候选人入职，拜托推荐人看紧哦。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <i 
                                    v-if="!recommendProcess.isMarketRecommendation && recommendProcess.canCreateOfferInfo"
                                    class="text-orange" 
                                    style="margin-right:5px;" 
                                >*猎头未填写业绩分成</i>
                                <button class="btn btn-primary" @click="stage(16, '入职')" v-if="!recommendProcess.isMarketRecommendation && !recommendProcess.canCreateOfferInfo">
                                    入 职
                                </button>
                                <!-- <button class="btn btn-primary" @click="stage(16, '入职')" v-if="!recommendProcess.isMarketRecommendation && ![3, 5].includes(recommendProcess.jobType)">
                                    入 职
                                </button> -->
                                <button class="btn btn-danger" @click="obsolete(recommendProcess.status)">
                                    淘 汰
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- 已offer end -->
                    
                    <!-- v-if="recommendProcess.status == 16" -->
                    <!-- [16, 24, 26, 32].includes(recommendProcess.status) -->
                    <div class="step" v-if="[16, 24, 26].includes(recommendProcess.status)">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <div class="media m-t-0">
                                    <div class="media-left">
                                        <i class="text-success fa fa-briefcase f-30 m-r-20"></i>
                                    </div>
                                    <div class="media-body" v-if="recommendProcess.status == 16">
                                        <p>
                                            已入职
                                        </p>
                                        <p>
                                            恭喜恭喜！还继续招人吗？别忘了继续打理您的职位！
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <add-customer-status
                                ref="addCustomerStatus"
                                v-if="recommendProcess.isCanRecommendToCustomer && recommendProcess.status == 16"
                                :is-recommend-to-customer="recommendProcess.isRecommendToCustomer"
                                :candidateStatus="recommendProcess.customerRecomendationViewModel.candidateStatus"
                                :recommendationStatus="recommendProcess.customerRecomendationViewModel.recommendationStatus"
                                @retry-recommend="handleRetryRecommend"
                                :recommend-id="myrecommendationId"
                            ></add-customer-status>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer && recommendProcess.status == 16"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-danger" @click="stage(32, '离职')">
                                    离 职
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="step" v-if="recommendProcess.status == 32">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <p class="title">
                                    <i class="text-danger fa fa-times-circle f-30 m-r-20 v-m"></i>候选人已离职！
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="step" v-if="recommendProcess.status == 1024">
                        <div class="d-t">
                            <div class="d-tbc desc">
                                <div class="media m-t-0">
                                    <div class="media-left">
                                        <i class="text-danger fa fa-times-circle f-30 m-r-20 v-m"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>
                                            候选人已淘汰：<span v-text="recommendProcess.obsoleteReason"></span>
                                        </p>
                                        <p>
                                            <span v-text="recommendProcess.obsoleteDetail || '无'"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-tbc opr" v-if="recommendProcess.isRecommender || recommendProcess.isPublisher">
                                <add-customer
                                    ref="addCustomer"
                                    v-if="recommendProcess.isCanRecommendToCustomer"
                                    v-show="!recommendProcess.isRecommendToCustomer"
                                    :recommend-id="myrecommendationId"
                                    :update-recommend-status="handleUpdateRecommendStatus"
                                ></add-customer>
                                <button class="btn btn-primary" @click="stage(1024, '恢复')">
                                    恢 复
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <stageDialog 
                ref="stageDialog" 
                :status="stageStatus" 
                @refresh-lock-info="() =>{this.$emit('refresh-lock-info')}" 
                @successFn="SuccessRefresh"
            ></stageDialog>
            <obsoleteDialog 
                ref="obsoleteDialog" 
                @successFn="SuccessRefresh"
            ></obsoleteDialog>
            <interviewDialog 
                ref="interviewDialog" 
                @successFn="SuccessInterviewRefresh"
            ></interviewDialog>
            <!-- <acceptDialog 
                ref="acceptDialog" 
                @successFn="SuccessRefresh"
            ></acceptDialog> -->
            <acceptDialog 
                ref="acceptDialog" 
                @successFn="acceptSuccessRefresh"
            ></acceptDialog>
            <rejectDialog 
                ref="rejectDialog"
                @successFn="SuccessRefresh"
            ></rejectDialog>
            <offerDialog 
                ref="offerDialog" 
                @successFn="offerSuccessRefresh" 
                @successEditOffer="$emit('successEditOffer')" 
                class="recommend-offer-vue"
            ></offerDialog>
            <processDialog 
                ref="processDialog" 
                @successFn="SuccessRefresh"
            ></processDialog>
            <cancelDialog 
                ref="cancelDialog" 
                @successFn="SuccessRefresh"
            ></cancelDialog>
            <approveDialog 
                ref="approveDialog" 
                @successFn="SuccessRefresh"
            ></approveDialog>

            <!-- 进入面试和确认进入面试 -->
            <enter-sure-interview-dialog
                ref="enterSureInterviewDialog"
                @successFn="SuccessRefresh"
                >
            </enter-sure-interview-dialog>

            <!-- <redDialog 
                ref="redDialog" 
                class="recommend-redDialog"
            ></redDialog> -->

            <!-- 淘汰候选人 -->
            <!-- offer 移除 -->
            <!-- <div class="hide" type="text/template" id="offerTemplate">
                <div class="offer resume-progress-modal">
                    <form class="form-normal">
                        <div class="form-group clearfix text-left">
                            请确定是否已发送offer？
                        </div>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import stageDialog from '#/component/common/dialog/stage-dialog.vue';
import obsoleteDialog from '#/component/common/dialog/obsolete-dialog.vue';
import interviewDialog from '#/component/common/dialog/interview-dialog.vue';
import acceptDialog from '#/component/common/dialog/accept-dialog.vue';
import rejectDialog from '#/component/common/dialog/reject-dialog.vue';
import offerDialog from '#/component/common/dialog/offer-dialog.vue';
import processDialog from '#/component/common/dialog/process-dialog.vue';
import cancelDialog from '#/component/common/dialog/cancel-dialog.vue';
import approveDialog from '#/component/common/dialog/approve-dialog.vue';
// import redDialog from '#/component/common/dialog/red-dialog.vue';
import AddCustomer from '#/component/pingan/add-customer.vue';
import AddCustomerStatus from '#/component/pingan/add-customer-status.vue';
import EnterSureInterviewDialog from '#/component/common/dialog/enter-sure-interview-dialog.vue';

import floatingListService from '#/js/service/floatingListService.js';
import myService from '#/js/service/myService.js';

import moment from 'moment';

function markPadding(str, size){
    if(str.length < size){
        return makeEmpyArray(size - str.length).join('') + str;
    }else{
        return str;
    }
}
function makeEmpyArray(size, filling){
    return Array.apply(null, Array(size || 4)).map(Number.prototype.valueOf,filling || 0)
}

    export default {
        name: "recommendProcess",
        // mixins : [
        //     stateMixin,
        //     eventMixin,
        //     componentMixin
        // ],
        components:{
            stageDialog,
            obsoleteDialog,
            interviewDialog,
            acceptDialog,
            rejectDialog,
            offerDialog,
            processDialog,
            cancelDialog,
            approveDialog,
            // redDialog,
            AddCustomer,
            AddCustomerStatus,
            EnterSureInterviewDialog,
        },
        data(){
            return{
                recommendProcessLoaded: false,
                recommendProcess: {},
                myrecommendationId: this.recommendationId,
                stageStatus: 16,
                offeringMap: {
                    7: 'S1全部面试通过',
                    9: 'S2提交流水等材料',
                    10: 'S3客户审批offer',
                    11: 'S4沟通offer细节',
                    12: 'S5等待接受offer',
                    13: 'S6双方确认offer',
                },
                candidateRecordLoading: false,
                
                flowStatusUpdated: '',
                expirationDate: '',
                withinDays: '', // 在多少天以内
            }
        },
        props:{
            isShowStep: {
                type: Boolean,
                default: true
            },
            recommendationId:{
                type:String,
                default:''
            },
            isPmOrHrPage: {
                type: Boolean,
                default: false
            },
        },
        computed:{
            extraTip(){
                let status = this.recommendProcess.status;
                let str = "";
                if (status == -8) {
                    str = "待筛选";
                } else if (status == 0) {
                    str = "待反馈";
                } else if (status == 2) {
                    str = "已拒绝";
                } else if (status == 32) {
                    str = "已离职";
                } else if (status == 1024) {
                    str = "已淘汰";
                }
                return str;
            },
            extraTipClass(){
                let status = this.recommendProcess.status, lastStatus = this.recommendProcess.lastStatus;
                let str = "";
                if (status == 1024 && lastStatus == 0 || lastStatus == -8) {
                    str = "show extra-tip-left text-danger";
                } else if (status == 0 || status == -8) {
                    str = "show extra-tip-left";
                } else if (status == 2) {
                    str = "show extra-tip-left text-danger";
                } else if (status == 32) {
                    str = "show extra-tip-right text-danger";
                }
                return str;
            },
            interviewsLast(){
                return this.recommendProcess.interviews.length - 1;
            },
            canAtPublisherWhenInterviewing(){
                let interviews = this.recommendProcess.interviews;
                if (interviews.length) {
                    let interview = interviews[interviews.length - 1];
                    let time = interview.interviewTime;
                    time = new Date(time && time.replace(/-/g, "/"));
                    if (time < new Date()) {
                        return true;
                    }
                }
                return false;
            },
            interviewid(){
                if (this.recommendProcess.interviews.length>0){
                    return this.recommendProcess.interviews[this.interviewsLast].id;
                }
                return false;
            },
            isOnboardLock() {
                return this.$store.state.candidateDetail.lockStatus.isOnboardLock;
            },
            isCFuser(){
                return this.$store.state.user.userInfo.isCFUser;
            },
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        watch: {
            flowStatusUpdated(val) {

                let dayRange;
                let diffDateMsec;
                let toDayTime = Date.now();
                let flowStatusUpdateTime = Date.parse(this.flowStatusUpdated);
                let tempDay = new Date(this.flowStatusUpdated);
                // console.log(toDayTime);
                // console.log(flowStatusUpdateTime);
                diffDateMsec = Math.abs(toDayTime - flowStatusUpdateTime) // 取相差毫秒数的绝对值
                dayRange = Math.floor(diffDateMsec / (24 * 60 * 60 * 1000)) // 向下取整相差的天数
                console.log(dayRange);

                // 推荐流程长时间没更新判断
                if((30 - dayRange) >= 0) {
                    this.withinDays = '30';
                    this.expirationDate = '';
                } else if((60 - dayRange) >= 0 && (dayRange - 30) > 0) {
                    this.withinDays = '60';
                    // 将日期增加 90 天
                    tempDay.setDate(tempDay.getDate() + 90);
                    // 获取年、月、日、时、分
                    var year = tempDay.getFullYear();
                    var month = tempDay.getMonth() + 1; 
                    var day = tempDay.getDate();
                    let hours = tempDay.getHours();
                    let minutes = tempDay.getMinutes();
                    // this.expirationDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
                    this.expirationDate = year + '-' + month + '-' + day;
                    this.expirationDate = moment(this.expirationDate).format('YYYY-MM-DD');
                    // console.log(this.expirationDate);
                } else if((90 - dayRange) >= 0 && (dayRange - 60) > 0) {
                    this.withinDays = '90';
                    this.expirationDate = 90 - dayRange;
                } else {
                    this.withinDays = '';
                    this.expirationDate = '';
                }
            },
            isOnboardLock(val) {
                if(!val) {
                    this.SuccessRefresh();
                }
            }
        },
        mounted() {
            this.$store.dispatch('setRecommendPosition', 'candidateDetail');
        },
        filters: {
            smartMonth(str){
                const d = new Date(str.replace(/-/g,'/'));
                return markPadding(d.getMonth() + 1 + '', 2)+'月';
            },
            smartDay (str) {
                const d = new Date(str.replace(/-/g,'/'));
                return markPadding(d.getDate() + '', 2);
            }
        },
        methods: {
            init() {
                const params = {
                    recommendationId: this.myrecommendationId,
                    unionId: this.unionId,
                };
                this.candidateRecordLoading = true;
                myService.getRecommendProcess(this.myrecommendationId, params)
                    .then(res => {
                        this.recommendProcess = res;
                        this.flowStatusUpdated = res.flowStatusUpdated;
                        if (this.recommendProcessLoaded === false) {
                            this.recommendProcessLoaded = true;
                        }
                        this.$emit('recommend-process-success', res);
                    })
                    .finally(() => {
                        this.candidateRecordLoading = false;
                    });
            },
            showRepeatResume(){
                this.$emit('similar-resume');
            },
            transfer(){
                this.$emit('transfer')
            },
            promoteEnterInterview(){
                this.$emit('comment-at-and-tip',this.recommendProcess.recommenderInfo.nickname);
            },
            refresh(id) {
                if(id){
                    this.myrecommendationId = id;
                }
                this.init();
            },
            stage(status, btnText) {
                if(status == 1024) {
                    myService.getLastStatus(this.recommendProcess.id).then((res) => {
                        this.stageStatus = 1024;
                        this.$refs.stageDialog.showHandle(this.recommendProcess.id, btnText, res);
                    }).catch(err => {
                        shortTips(err.message || '获取权限失败');
                    });
                } else if(status == 4){
                    this.$refs.enterSureInterviewDialog.showHandle({
                        "btnText": btnText,
                        "id": this.recommendationId,
                        "realName": this.recommendProcess.interviewViewModel.candidateRealName,
                        "jobName": this.recommendProcess.interviewViewModel.jobName,
                        "headhunterRealName": this.recommendProcess.recommenderInfo.realName || '',
                        "headhunterNickname": this.recommendProcess.recommenderInfo.nickname || '',
                        recommend: this.recommendProcess,
                        nextStatus: 4,
                    });
                } else {
                    this.stageStatus = status;
                    this.$refs.stageDialog.showHandle(this.recommendProcess.id, btnText, '', this.recommendProcess.status);
                }
            },
            obsolete(status) {
                this.$refs.obsoleteDialog.showHandle(this.recommendProcess.id, status, '淘汰');
            },
            process(){
                this.$refs.processDialog.showHandle(this.recommendProcess.interviews[this.recommendProcess.interviews.length - 1].id, '反馈面试结果');
            },
            cancel(interviewid){
                this.$refs.cancelDialog.showHandle(this.recommendProcess.id,interviewid);
            },
            approve(){
                this.$refs.approveDialog.showHandle(this.recommendProcess.id);
            },
            interview(btnText) {
                let contacterFullName = this.recommendProcess.interviewViewModel.contacterFullName.split('@');
                let candidateinfo = {
                    'btnText': btnText,
                    'realName':this.recommendProcess.interviewViewModel.candidateRealName,
                    'jobName':this.recommendProcess.interviewViewModel.jobName,
                    'headhunterRealName':contacterFullName[0],
                    'headhunterNickname':contacterFullName[1],
                    'id':this.recommendProcess.id
                }
                this.$refs.interviewDialog.showHandle(candidateinfo);
            },
            async accept(btnText) {
                await floatingListService
                    .checkCandidate([this.recommendProcess.id])
                    .then(res => {
                        let isShowMoreText = false;
                        if(res.length > 0){
                            isShowMoreText = true;
                        }
                        this.$refs.acceptDialog.showHandle(this.recommendProcess.id, btnText, isShowMoreText || false);
                    })
            },
            reject() {
                this.$refs.rejectDialog.showHandle(this.recommendProcess.id, '拒绝');
            },
            offer() {
                // this.$refs.offerDialog.showHandle(this.recommendProcess.id,true, 'offer');
                this.$refs.offerDialog.showHandle(this.recommendProcess.id,true, 'offer', false, null, this.recommendProcess);
            },
            // oneKeyOffer(){
            //     this.$refs.offerDialog.showHandle(this.recommendProcess.id,false, '一键offer');
            // },
            acceptSuccessRefresh() {
                // 推荐简历附件刷新
                this.$emit('attachmentRefresh');
                
                this.refresh(this.myrecommendationId);//进度刷新
                this.$emit('timelineRefresh');//时间线刷新
            },
            SuccessRefresh(res) {
                this.refresh(this.myrecommendationId);//进度刷新
                this.$emit('timelineRefresh');//时间线刷新
            },
            SuccessInterviewRefresh(res) {
                this.refresh(this.myrecommendationId);//进度刷新
                this.$emit('timelineRefresh');//时间线刷新

                this.$emit('successInterview');//安排面试后刷新云端推荐详情
            },
            offerSuccessRefresh(res){
                this.refresh(this.myrecommendationId);//进度刷新
                this.$emit('timelineRefresh');//时间线刷新
                /* if(this.isCFuser){
                    this.$refs.redDialog.showHandle(res.offerRedPacketViewModel)
                } */
            },
            handleUpdateRecommendStatus(recommendationStatus, candidateStatus) {
                this.recommendProcess.isRecommendToCustomer = true;
                this.$refs.addCustomerStatus.updateLocalModel({recommendationStatus, candidateStatus});
            },
            handleRetryRecommend() {
                this.$refs.addCustomer && this.$refs.addCustomer.recommend();
            },
            offerNext() {
                this.$confirm(`确认进入到下一阶段？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(async () => {
                    this.candidateRecordLoading = true;
                    await this.updateOfferingStage();
                    this.candidateRecordLoading = false;
                    shortTips(`操作成功~`);
                    this.SuccessRefresh();
                }).catch(() => {
                });
            },
            updateOfferingStage(isCompleteOffering) {
                if(this.recommendProcess.status == 13) return Promise.resolve(true);
                const _nextStatus = this.recommendProcess.status == 7 ? 9: this.recommendProcess.status + 1;
                const params = {
                    isCompleteOffering: isCompleteOffering || false,
                    status: _nextStatus,
                    recommendationId: this.myrecommendationId,
                };
                // return myService.updateOfferingStage(params);
                return myService.recommendOfferingStage(params);
            },
            getStep(recommendProcess) {
                if([7, 9, 10, 11, 12, 13].includes(recommendProcess.status)) {
                    return '7';
                } else if(recommendProcess.status == 407) {
                    return '4';
                } else {
                    return String(recommendProcess.status);
                }
            },
            enterOffering(status, btnText) {
                // this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                this.stageStatus = status;
                this.$refs.stageDialog.showHandle(this.recommendProcess.id, btnText);
                // this.$refs.stageDialog.showHandle(this.recommendProcess.id, btnText, '', this.recommendProcess.status);
            },
        },

    }
</script>

<style lang="scss">
    @import "./recommend-process-refactor.scss";
    .el-tooltip__popper.progress-tooltip {
        padding: 19px;
        max-width: 230px !important;
    }
    .resume-progress {
        .opr .btn-info {
            margin-right: 10px;
        }

        .add-customer-status {
            text-align: right;

            .warn .text {
                margin-right: 0;
            }
        }

        .progress-out-tip {
            // margin-left: 20px;
            color: #333;
            .text-red {
                color: red;
            }
            .tip-icon {
                color: red;
                margin-left: 2px;
                margin-bottom: 2px;
                cursor: pointer;
            }
        }
    }
    .resume-progress-btn{
        .modal-box-foot{
            .btn{
                padding: 0;
            }
        }
        .recommend-offer-vue{
            .offerDialog-month{
                .el-form-item__error{
                    margin-left: -80px;
                    padding-top: 10px;
                }
            }
        }
        .recommend-redDialog{
            .modal-red {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9999;
                background-color: rgba(0, 0, 0, .5);
            }

            .modal-red .modal-red-content {
                position: absolute;
                width: 1360px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 600px;
                left: 0;
                right: 0;
            }

            #modalRed .modal-close {
                display: block;
                position: absolute;
                right: 20px;
                top: 20px;
                transform: scale(2);
                z-index: 1;
            }

            #modalRed .modal-close:hover {
                cursor: pointer;
            }

            .modal-red .title {
                position: absolute;
                width: 100%;
                color: #ffee18;
                top: -100px;
                text-align: center;
                transition: all .5s ease-in;
            }

            .modal-red.active .title {
                top: 120px;
            }

            .modal-red .title p {
                font-size: 60px;
                margin: 10px 0;
                text-shadow: 0 2px 4px rgba(0, 0, 0, .5);
            }

            .modal-red .title h1 {
                font-size: 42px;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            }

            .modal-red .red {
                position: absolute;
            }

            .modal-red .red01 {
                width: 296px;
                height: 266px;
                top: -30px;
                right: 100px;
                transition: all .5s ease-in .2s;
                transform: scale(1);
            }

            .modal-red .red02 {
                width: 403px;
                height: 382px;
                top: -115px;
                left: 130px;
                transition: all .3s ease-in .2s;
                transform: scale(1);
            }

            .modal-red.active .red01 {
                //transform: scale(1);

            }

            .modal-red.active .red02 {
                //transform: scale(1);
            }

            .modal-red .red-main {
                position: relative;
                top: 85px;
                margin: 0 auto;
                width: 316px;
                height: 441px;
                text-align: center;
                background: url("//hstatic.hirede.com/lbd/images/red_main.png") no-repeat 0 0;
                transition: all .3s ease-in .4s;
                transform: scale(1);
            }

            .modal-red.active .red-main {
                transform: scale(1);
            }

            .modal-red .red-main img.red-code {
                margin: 200px auto 10px;
                width: 130px;
            }

            .modal-red .red-main h2 {
                margin: 10px 0;
                font-size: 22px;
                color: #fff;
                font-style: normal;
            }
        }
        .btn {
            line-height: 34px;
            height: 34px;
            min-width: 90px;
            padding: 0;
            border-radius: 5px;
            font-size: 14px;
        }
    }
</style>
