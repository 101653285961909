<template>
    <div class="recommed-comment" v-loading="loading">
        <!-- <div class="recomand-evaluate-title"><font-icon href="#icon-wechat" class="candidate-icon"></font-icon>推荐评价</div> -->
        <div class="comment-wrapper">
            <div class="comments-wrap">
                <div id="commentPanelId2" class="coment-wrap">
                    <div v-show="canEvalute ||canReply">
                        <div class="reply-tip" v-if="operationType == 'reply'">
                            对 <span class="user">{{replyUser}}</span> 的内容 <span class="origin-content">“{{replyContent}}”</span> 进行回复：
                        </div>
                        <p class="score-and-tip" v-else>
                            <!-- <span>匹配度评分</span> -->
                            <span class="star-wrap">
                                <span class="star-item" :class="{'disabled-click': hasEvalute}" v-for="(star, index) in stars" :key="'star_' + index" @click="updateStarRating(index + 1)" >
                                    <!-- <i class="el-icon-star-on" v-if="star"></i>
                                    <i class="el-icon-star-off" v-else></i> -->
                                    <font-icon class="star-on" href="#icon-a-rencaiyishoucang" v-if="star"></font-icon>
                                    <font-icon class="star-off" href="#icon-shoucang_fill" v-else></font-icon>
                                </span>
                            </span>
                            <span class="tip-text" v-if="score">
                                <span class="score-text">{{score}}分</span>

                                <i v-if="score == '1'">非常不匹配，不建议推荐</i>
                                <i v-if="score == '2'">匹配度较低，推荐需慎重考虑</i>
                                <i v-if="score == '3'">基本匹配，缺乏亮点</i>
                                <i v-if="score == '4'">较为匹配，有一定竞争力</i>
                                <i v-if="score == '5'">非常匹配，很建议推荐给客户</i>
                            </span>
                        </p>

                        <form class="form-horizontal" @submit.prevent="add" method="post" name="comment">
                            <div class="form-group">
                                <div class="atWrap">
                                    <textarea class="evalute-textarea" :disabled="hasEvalute" rows="4" id="comment" data-haha="123" v-bind="{'data-isMyJob': commentObjectInfo.isMyJob ? 1 : 0}" name="comment" placeholder="" data-maxLength=""></textarea>
                                    <div class="at-tools clearfix" :class="{'textarea-disabled': hasEvalute}">
                                        <div class="pull-right">
                                            <input type="hidden" name="privacy" :value="privacy" />
                                            <el-dropdown @command="handlePrivacyCommand">
                                                <span class="el-dropdown-link">
                                                    <!-- 下拉菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
                                                    <a v-if="privacy == 0" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><font-icon href="#icon-chakan"></font-icon><span>公开</span></a>
                                                    <a v-if="privacy == 1" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><font-icon href="#icon-suo1"></font-icon><span>私密</span></a>
                                                </span>
                                                <el-dropdown-menu slot="dropdown" class="privacy-dropdown-menu">
                                                    <el-tooltip class="item" effect="light" content="所有人能看到评论" placement="left">
                                                        <el-dropdown-item command="0">
                                                            <font-icon href="#icon-chakan"></font-icon>公开
                                                        </el-dropdown-item>
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="light" content="只有被@的人才能看到评论" placement="left">
                                                        <el-dropdown-item command="1">
                                                            <font-icon href="#icon-suo1"></font-icon>私密
                                                        </el-dropdown-item>
                                                    </el-tooltip>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                        <!-- <div class="pull-right comment-image">
                                            <a href="javascript:;"><i class="fa fa-paperclip"></i></a>
                                            <input id="commentWithImageId" title="添加附件" class="add-image" type="file" name="file" value="0" />
                                        </div> -->
                                        <div class="pull-right">
                                            <input type="hidden" name="atUserIds" />
                                            <el-popover
                                                placement="bottom"
                                                title=""
                                                width="370"
                                                trigger="click"
                                                v-model="atListPopoverVisible"
                                            >
                                                <div class="atList" aria-labelledby="atlist">
                                                    <div class="search">
                                                        <input @keyup="searchUsers" @keydown="stopEvent" autocomplete="off" type="text" name="keyword" placeholder="查找更多好友" />
                                                    </div>
                                                    <ul>
                                                        <template v-for="(user, index) in users">
                                                            <p v-if="user.type == 1 && (index == (users.length-2))" class="bazaSelectMenuTipsBox">小猎或其他用户</p>
                                                            <li>
                                                                <a @click="selectPerson(user, $event)" href="javascript:;">
                                                                    <img v-bind:src="user.avatar | avatar" width="16" />
                                                                    <span class="team-tip" v-if="user.isFirmTeam">团队</span>
                                                                    <span class="realName" v-text="user.realName"></span>
                                                                    <span class="nickname">{{ user.nickname ? `@${user.nickname}` : '' }}</span>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                                <a slot="reference" class="at-list-icon" id="atlist" title="对TA说" href="javascript:;" @click="getClickAtUserInfo">@</a>
                                            </el-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 附件列表 -->
                            <!-- <div class="comment-image preview clearfix">
                                <div class="image-item" v-for="imagePreview in imagePreviewList">
                                    <img v-bind="{src: imagePreview.cropPicture.url, 'data-thumbnailid': imagePreview.cropPicture.id, 'data-bigpictureid': imagePreview.bigPicture.id}">
                                    <i class="fa fa-close delete"></i>
                                </div>
                            </div>
                            <div class="comment-attachment preview clearfix">
                                <div class="attachment-item" v-for="attachmentPreview in attachmentPreviewList">
                                    <i class="fa fa-paperclip text-primary"></i>
                                    <a class="text-light" target="_blank" title="下载附件" v-text="attachmentPreview.name || 'xxx'"></a>
                                    <i class="fa fa-close delete"></i>
                                </div>
                            </div> -->
                            <div class="comment-operation">
                                <div class="col-xs-6 email-operation">
                                    <span id="error" class="text-danger hide"></span>
                                    <p v-show="isEmail" class="email-checkbox">
                                        <el-checkbox v-model="isEmailNotic">发送邮件通知</el-checkbox>
                                    </p>
                                </div>
                                <div class="col-xs-6 text-right">
                                    <input class="btn btn-primary btn-background" id="publish" type="submit" value="发表" v-bind="{ disabled: !canPost }" :disabled="hasEvalute" :class="{'disabled-click': hasEvalute}" />
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- 评分列表 -->
                    <div class="evaluate-list" v-for="item in evaluateList" :key="item.id">
                        <div class="top">
                            <div class="top-info">
                                <avatar
                                    :userId="item.posterId"
                                    :src="item.avatarUrl"
                                    :enableLink="true"
                                    :enableName="false"
                                    :realName="item.posterName"
                                    :nickName="item.posterNickname"
                                    :isRecommendation="true"
                                    :shareData="shareData"
                                    size="md"
                                ></avatar>
                                <div class="name-and-score">
                                    <p class="name">{{ item.posterName }}@{{ item.posterNickname }}</p>
                                    <p class="star-wrap">
                                        <span class="star-item">
                                            <!-- <i class="el-icon-star-on" v-for="(star, index) in computedParse(item.content,'score')" :key="'starOn_' + index"></i>
                                            <i class="el-icon-star-off" v-for="(star, index) in 5-computedParse(item.content,'score')" :key="'starOff_' + index"></i> -->
                                            <font-icon class="star-on" href="#icon-a-rencaiyishoucang" v-for="(star, index) in computedParse(item.content,'score')" :key="'starOn_' + index"></font-icon>
                                            <font-icon class="star-off" href="#icon-shoucang_fill" v-for="(star, index) in 5-computedParse(item.content,'score')" :key="'starOff_' + index"></font-icon>
                                        </span>
                                        <span class="score-text">{{ computedParse(item.content,'score') }}分</span>
                                    </p>
                                </div>
                            </div>
                            <div class="top-time">
                                <span class="edit"
                                    v-if="item.posterId === userInfo.id && canEvalute" 
                                    @click="editEvaluate(item)">
                                    <font-icon class="edit-icon" href="#icon-bianji"></font-icon>编辑
                                </span>
                                {{ item.created | formatDate("YYYY-MM-DD HH:mm") }}
                            </div>
                        </div>
                        <div class="bottom">
                            <span v-if="item.privacy == 1" class="secret" title="私密">密</span>
                            <span v-html="formatHtml(computedParse(item.content,'text'), item.atUsers)" class="detail-content"></span>
                        </div>
                        <div class="operation-bar">
                            <span class="reply"
                                @click="replyEvaluate(item)">
                                <font-icon class="reply-icon" href="#icon-xinliweibao-pinglun"></font-icon>
                            回复</span>
                            <div class="toggle" v-if="item.replyCount>0">
                                <span class="tip-text">
                                    <span class="count">{{item.replyCount}}</span> 条回复
                                </span>
                                <span 
                                    @click="toggle(item)"
                                    v-if="showSpread(item)"
                                    >展开回复
                                    <font-icon class="toggle-icon" href="#icon-toggle-open"></font-icon>
                                </span>
                                <span 
                                    @click="fold(item)"
                                    v-if="showFold(item)"
                                    >收起回复
                                    <font-icon class="toggle-icon" href="#icon-toggle-close"></font-icon>    
                                </span>
                            </div>
                        </div>


                        <!-- 回复列表 -->
                        <div class="reply-list" v-if="currentComment == item.id && replyList.length>0">
                            <div    
                                class="reply-cell" 
                                v-for="(sItem,index) in replyList" 
                                :key="'replay_' + index">
                                <div class="cell-top">
                                    <div class="pic">
                                        <img :src="sItem.avatarUrl+'?size=60'" />
                                    </div>
                                    <div class="content-r">
                                        <div class="top-line">
                                            <div class="top-l">
                                                <span class="name">{{sItem.posterName}}@{{sItem.posterNickname}}</span>
                                                <span class="time">{{sItem.created | formatDate("YYYY-MM-DD HH:mm")}}</span>
                                            </div>
                                            <div class="top-r">
                                                <span class="reply"
                                                    @click="replyEvaluate(sItem)">
                                                    <font-icon class="reply-icon" href="#icon-xinliweibao-pinglun"></font-icon>
                                                回复</span>
                                            </div>
                                        </div>
                                        <div class="content" v-html="computedReplyConted(sItem)">用户需求<span>@zhougs</span> 这个职位推</div>
                                    </div>
                                </div>
                                <div class="origin-content">
                                    <div class="origin-name">{{sItem.belongCommentPosterName}}@{{sItem.belongCommentPosterNickName}}</div>
                                    <div class="origin-text">{{ (sItem.belongCommentContent.text||sItem.belongCommentContent) | overEllipsis(30) }}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-if="!canEvalute && evaluateList.length == 0">
                        无评分数据
                    </div>
                </div>
            </div>
        </div>

        <!-- <el-dialog
            title="删除评论"
            :visible.sync="deleteDialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>确定删除这条评论吗</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDelete">取消</el-button>
                <el-button v-loading="confirmDeleteLoading" type="primary" @click="confirmDelete">确定</el-button>
            </span>
        </el-dialog> -->
        <el-dialog
            title="提示"
            :visible.sync="atNoticeDialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>你只@了自己，只有自己才能收到哦！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelAtNotice">取消</el-button>
                <el-button v-loading="confirmAtNoticeLoading" type="primary" @click="confirmAtNotice">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="确定发表吗？"
            :visible.sync="withoutAtDialogVisible"
            width="320px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>你好像想对别人留言，但没有@任何人。<br/>@了别人才能收到消息哦！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelWithoutAt">取消</el-button>
                <el-button v-loading="confirmWithoutAtLoading" type="primary" @click="confirmWithoutAt">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import '#/component/comments/widget.js';
    import '#/component/comments/jquery.baza.atSelect-vue.js';
    import atSystemMixin from './atSystem-vue';
    import Avatar from '#/component/common/avatar.vue';
    import Divider from '#/component/common/divider.vue';
    import CommentItem from '#/component/comments/commentItem';
    import { job as jobUrl } from '#/js/config/api.json';
    import jobService from '#/js/service/jobService.js';

    export default {
        components:{
            Avatar,
            Divider,
            CommentItem,
        },
        mixins: [
            atSystemMixin
        ],
        computed:{
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            userId() {
                return this.$store.state.user.userInfo.id;
            }
        },
        data() {
            return {
                loading: false,
                stars: [false, false, false, false, false],
                score: '',
                evaluateList: [],
                commentId: '',
                hasEvalute: false,

                commentType: this.$attrs.commentType || '',
                placeholderText: this.$attrs.placeholderText || '',
                atsystem: null,
                isEmailNotic:this.isEmail,
                isShowOperation: false,
                deleteDialogVisible: false,
                deleteCommentId: '',
                confirmDeleteLoading: false,
                atNoticeDialogVisible: false,
                confirmAtNoticeLoading: false,
                withoutAtDialogVisible: false,
                confirmWithoutAtLoading: false,
                privacy: 0,
                atListPopoverVisible: false,

                operationType: '', // 操作类型
                replyUser: '', // 回复用户
                replyContent: '', // 回复内容
                
                replyList: [], // 回复列表数据
                currentComment: '', // 当前展开id
                canReply: false,
            }
        },
        props: {
            canEvalute: Boolean,

            commentTitle:{
                type: String,
                default: '评论'
            },
            prefetch: Boolean,
            canPost: {
                type: Boolean,
                default: true
            },
            team: Boolean,
            isEmail: Boolean,
            commentObjectInfo: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            shareData: Object
        },
        watch: {
            // commentObjectInfo: function(val) {
            //     this.refreshNodes();
            //     this.$comment.data('AtSelect').options.isMyJob = this.commentObjectInfo.isMyJob;
            //     console.log(this.$comment.data('AtSelect').options);
            // }
            '$route.params.recommendationid': {
                handler(val) {
                    if(val) {
                        this.stars = [false, false, false, false, false];
                        this.score = '';
                        this.isEmailNotic = this.isEmail;
                        this.privacy = 0;
                        $('#commentPanelId2').find("#comment").val('');
                        
                        this.getEvaluateList();

                        this.refreshNodes();
                        this.operationType = '';
                    }
                },
                immediate: true, 
                deep: true,
            }
        },
        mounted () {
            $('#commentPanelId2').find("#comment").val('');
        },
        methods: {
            getReplyCanReply() {
                let recommendationId = this.$route.params.recommendationid;
                this.replyList = this.replyList.map(async it => {
                    const _isCanReply = await this.checkCanReply(recommendationId, it.id);
                    return {
                        ...it,
                        isCanReply: _isCanReply
                    }
                });
                console.log(`this.replyList:`, this.replyList)
            },
            getEvaluateCanReply() {
                let recommendationId = this.$route.params.recommendationid;
                this.evaluateList = this.evaluateList.map(async it => {
                    const _isCanReply = await this.checkCanReply(recommendationId, it.id);
                    return {
                        ...it,
                        isCanReply: _isCanReply
                    }
                });
                console.log(`this.evaluateList:`, this.evaluateList)
            },
            checkCanReply(recommendationId, replyCommentId) {
                return new Promise((resolve,reject) => {
                    jobService
                    .checkCanEvaluate(recommendationId, replyCommentId)
                    .then(res => {
                        resolve(res.success);
                    });
                });
            },
            // 点击星星修改评价
            updateStarRating(rating) {
                // console.log(rating);
                if(this.hasEvalute) {
                    return;
                } else {
                    this.score = rating;
                    for (let i = 0; i < 5; i++) {
                        this.stars[i] = i < rating; // 根据评分更新星星状态
                    }
                }
            },
             // 获取评分列表
            getEvaluateList() {
                this.loading = true;
                let recommendationId = this.$route.params.recommendationid;
                _request({
                    url: jobUrl.recommend_evalute_list.replace(/\%p/ig, recommendationId),
                    method: "GET",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                }).then((res) => {
                    // console.log(res);
                    this.evaluateList = res.list;
                    this.hasEvalute = this.evaluateList.some(el => {
                        return el.posterId == this.userInfo.id;
                    })
                    // this.getEvaluateCanReply();
                    this.loading = false;

                    this.$emit('is-evalute', this.hasEvalute);

                    this.replyList = []; // 回复数据清空
                    this.currentComment = '';
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            computedParse(content, type) {
                // console.log(content);
                if(typeof JSON.parse(content) === 'object') {
                    if(type == 'score') {
                        return JSON.parse(content).score;
                    } else {
                        return JSON.parse(content).text;
                    }
                } else {
                    return '';
                }
            },
            

            // refresh(){
            //     this.refresh();
            // },
            setCommentType(commentType){
                this.commentType = commentType;
            },
            setPlaceHolderText(placeholderText){
                this.placeholderText = placeholderText;
            },
            atAndTip(id){
                $(window).scrollTop(this.$comment.offset().top);
                this.$comment.val('@' + id + ' ');
                this.$comment.data('AtSelect').lookup();
            },
            formatHtml(str, arr) {
                return str.replace(/\[userId:'([^\]]+)*?'\]/g, function ($1, $2) {
                    if (!arr) {
                        return $1;
                    }
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i].userId == $2) {
                            return "<span class='idCard text-primary lbd-cursor-pointer' data-id=" + arr[i].userId + ">@" + arr[i].nickname + "</span>";
                        }
                    }
                    return $1;
                });
            },
            cancelDelete() {
                this.deleteDialogVisible = false;
            },
            confirmDelete() {
                if(this.confirmDeleteLoading) {
                    return false;
                }
                this.confirmDeleteLoading = true;
                _request({
                    url: '/Communication/Delete',
                    method: 'POST',
                    data: {
                        commentId: this.deleteCommentId
                    }
                }).then(res => {
                    this.refresh();
                    shortTips('评论删除成功');
                    this.deleteDialogVisible = false;
                }).finally(() => {
                    this.confirmDeleteLoading = false;
                })
            },
            cancelAtNotice() {
                this.atNoticeDialogVisible = false;
            },
            confirmAtNotice() {
                if(this.confirmAtNoticeLoading) {
                    return false;
                }
                this.confirmAtNoticeLoading = true;
                this.ajaxContent(() => {
                    this.atNoticeDialogVisible = false;
                });
            },
            cancelWithoutAt() {
                this.withoutAtDialogVisible = false;
            },
            confirmWithoutAt() {
                if(this.confirmWithoutAtLoading) {
                    return false;
                }
                this.confirmWithoutAtLoading = true;
                this.ajaxContent(() => {
                    this.withoutAtDialogVisible = false;
                });
            },
            handlePrivacyCommand(val) {
                this.privacy = val;
            },
            computedReplyConted(item) {
                return `${item.privacy == 1 ? '<span class="privacy">密</span>': ''} ${this.formatHtml(item.content, item.atUsers)}`;
            },
        }
    }
</script>

<style lang="scss">
@import '#/component/comments/jquery.baza.atSelect.scss';

.recommed-comment {
    .disabled-click {
        opacity: .6;
        cursor: not-allowed !important;
    }
    .recomand-evaluate-title {
        margin-bottom: 15px;
        color: #444;
    }
    .comment-wrapper {
        // padding: 20px 12px;
        // border: 1px solid #eee;
        .score-and-tip {
            // padding: 0 12px;
            font-size: 14px;
            color: #333;
            display: flex;
            align-items: center;
            .star-wrap {
                // margin: 0 15px;
                color: #FF9E21;
                display: flex;
                align-items: center;
                .star-item {
                    cursor: pointer;
                    // i.el-icon-star-off {
                    //     font-size: 20px;
                    //     margin: 0 4px;
                    // }
                    // i.el-icon-star-on {
                    //     font-size: 25px;
                    //     margin: 0 4px;
                    // }
                    .icon {
                        font-size: 18px;
                        color: #FF9E21;
                        margin-right: 8px;
                    }
                }
                .score-text {
                    // margin-left: 8px;
                    color: #FF9E21;
                    margin-right: 5px;
                }
            }
            .tip-text {
                // color: #B4B4B4;
                color: #999;
                height: 19px;
            }
        }
        .reply-tip{
            line-height: 32px;
            .user{
                color: $primary;
            }
            .origin-content{
                font-weight: bold;
            }
        }

        .evaluate-list {
            // border-bottom: 1px solid #eee;
            // padding: 20px 12px;
            &:last-child {
                border-bottom: none;
            }
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-info {
                    display: flex;
                    align-items: center;
                    .avatar-container {
                        .avatar {
                            width: 32px;
                            height: 32px;
                        }
                    }
                    .name-and-score {
                        margin-left: 8px;
                        .name {
                            margin: 0;
                            color: #333;
                        }
                        .star-wrap {
                            margin: 0;
                            color: #FF9E21;
                            display: flex;
                            align-items: center;
                            .star-item {
                                display: flex;
                                align-items: center;
                                // i.el-icon-star-off {
                                //     font-size: 15px;
                                //     // margin-right: 5px;
                                // }
                                // i.el-icon-star-on {
                                //     font-size: 20px;
                                //     // margin-right: 5px;
                                // }
                                .icon {
                                    font-size: 13px;
                                    color: #FF9E21;
                                    margin-right: 4px;
                                }
                            }
                            .score-text {
                                // margin-left: 8px;
                                font-size: 12px;
                            }
                        }
                    }
                }
                .top-time {
                    // color: #B4B4B4;
                    color: #999999;
                    .edit{
                        font-size: 14px;
                        cursor: pointer;
                        margin-right: 15px;
                        .edit-icon{
                            color: #999;
                            font-size: 15px;
                            vertical-align: -2px;
                            margin-right: 4px;
                        }
                        &:hover{
                            color: $primary;
                            .edit-icon{
                                color: $primary;
                            }
                        }
                    }
                }
            }
            .bottom {
                margin-top: 10px;
                color: #666;
                padding-left: 40px;
                .secret {
                    color: #ff6666;
                    font-size: 12px;
                    border-radius: 3px;
                    border: 1px solid #ff6666;
                    padding: 1px 3px;
                    margin-right: 3px;
                }
            }
            .operation-bar{
                margin-bottom: 6px;
                margin-top: 4px;
                padding-left: 40px;
                font-size: 14px;
                display: flex;
                align-items: center;
                >div{
                    display: inline-block;
                    padding: 0 10px;
                }
                .reply{
                    cursor: pointer;
                    color: #999;
                    margin-right: 10px;
                    display: inline-flex;
                    align-items: center;
                    .reply-icon{
                        // color: $primary;
                        color: #999;
                        font-size: 18px;
                        margin-right: 5px;
                        // vertical-align: -2px;
                    }
                    &:hover{
                        color: $primary;
                        .reply-icon{
                            color: $primary;
                        }
                    }
                }
                .toggle{
                    color: #666;
                    text-align: right;
                    .tip-text{
                        color: #666;
                        margin-right: 6px;
                        cursor: default;
                        .count{
                            color: $primary;
                        }
                    }
                    >span{
                        cursor: pointer;
                        color: $primary;
                    }
                    .toggle-icon{
                        font-size: 15px;
                        margin-left: 4px;
                        vertical-align: -2px;
                    }
                }
            }

            .reply-list{
                padding-left: 40px;
                .reply-cell{
                    margin-bottom: 12px;
                    background: #F6F6F6;
                    border-radius: 5px;
                    padding: 12px 16px;
                    .cell-top{
                        display: flex;
                        margin-bottom: 10px;
                        .pic{
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin-right: 10px;
                        }
                        .content-r{
                            flex: 1;
                            .top-line{
                                font-size: 14px;
                                color: #999;
                                display: flex;
                                justify-content: space-between;
                                line-height: 22px;
                                .top-l{
                                    .name{
                                        color: #666;
                                        margin-right: 15px;
                                        font-weight: bold;
                                    }
                                    .time{
                                        // font-weight: lighter;
                                    }
                                }
                                .top-r{
                                    .reply{
                                        cursor: pointer;
                                        display: inline-flex;
                                        align-items: center;
                                        .reply-icon{
                                            // color: $primary;
                                            color: #999;
                                            font-size: 18px;
                                            margin-right: 5px;
                                            // vertical-align: -2px;
                                        }
                                        &:hover{
                                            color: $primary;
                                            .reply-icon{
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                            .content{
                                font-size: 14px;
                                line-height: 22px;
                                color: #333;
                                font-weight: 500;
                                .privacy{
                                    color: #FF4B4B;
                                    font-size: 12px;
                                    border-radius: 3px;
                                    border: 1px solid #FFA8A8;
                                    padding: 1px 3px;
                                    margin-right: 0;
                                    background-color: #FFECEC;
                                    
                                }
                                >span{
                                    color: $primary;
                                    margin: 0 4px;
                                }
                            }
                        }
                    }
                    .origin-content{
                        border-left: 3px solid #D8D8D8;
                        padding-left: 10px;
                        font-size: 14px;
                        line-height: 23px;
                        color: #999;
                        margin-top: 17px;
                        .origin-name{
                            color: #666;
                            font-weight: bold;
                        }
                        .origin-text{}
                    }
                }
            }
        }
    }

    .coment-wrap{
        .row-overflow{
            margin-left: -15px;
            margin-right: -15px;
            .email-checkbox{
                display: flex;
                align-items: center;
                .email-label{
                    margin-bottom: 0;
                    margin-left: 10px
                }
            }
        }
        .detail-content{
            word-wrap: break-word;
            white-space: pre-line;
        }
        ul{
            margin: 0;
            padding: 0;
        }

        .pull-right > .dropdown-menu {
            right: 0;
            left: auto;
        }
    }
    .coment-wrap .comments .avatar{
        border-radius: 50% !important;
        width: 60px;
        height: 60px;
    }
    .coment-wrap {
        // padding-top: 0;
        // padding-bottom: 40px;
        padding: 0;
        .title{
            margin-bottom: 10px;
            padding: 10px 0;
            margin-top: 50px;
            &:before {
                content: " ";
                display: inline-block;
                border-left: 8px solid $primary;
                height: 20px;
                margin-right: 5px;
                vertical-align: middle;
            }
            .job-detail-title,.title-text{
                font-size: 18px;
                vertical-align: middle;
            }

            .title-link {
                font-size: 16px;
                line-height: 32px;
                text-align: right;
                float: right;
            }
        }
    }

    .coment-wrap .fa:before,
    .coment-wrap .fa span {
        vertical-align: middle;
    }

    .coment-wrap .fa span {
        font-family: "微软雅黑", Arial;
    }

    .coment-wrap .btn-primary {
        font-size: 15px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .coment-wrap .btn-background{
        background-color: #38bc9c;
        &:hover{
            border: none;
        }
    }

    .coment-wrap .comments li {
        padding: 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececec;
        list-style: none;
    }

    .coment-wrap .comments li:last-child {
        border-bottom: 0 none;
    }

    .coment-wrap .comments h5 {
        padding: 0;
        margin: 0 0 5px;
        color: 10px;
        font-size: 14px;
    }

    .coment-wrap .comments  .detail .lbd-user-card {
        color: #333;
    }

    .coment-wrap .comments  .detail .nickname {
        color: #b9b9b9;
        font-size: 12px;
    }

    .coment-wrap .comments h5 .text-light {
        font-size: 14px;
    }

    .coment-wrap .comments h5 .title {
        font-size: 14px;
    }

    .coment-wrap .comments {
        margin-top: 50px;
        position: relative;

        .comment-list {
            padding: 0 15px;
        }
    }

    .coment-wrap .comments p {
        margin: 0;
        line-height: 22px;
    }

    .coment-wrap .comments li .detail {
        margin-left: 70px;
        margin-top: 5px;
        word-wrap: break-word;
    }

    .coment-wrap .comments .secret,
    .coment-wrap .comments .sticky-top {
        color: #ff6666;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #ff6666;
        padding: 1px 3px;
    }

    .coment-wrap .comments .pull-left {
        position: relative;
        border-radius: 0;
        overflow: visible;
    }

    .coment-wrap .comments .hr-tip {
        position: absolute;
        right: -2px;
        bottom: 4px;
        padding: 1px 3px;
        font-size: 12px;
        font-weight: 100;
        background-color: rgba(94, 207, 186, 0.7);
        line-height: 9px;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }

    .coment-wrap .comments .btn-stage {
        margin-top: 10px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;
    }

    .coment-wrap .comments .btn-stage i {
        white-space: nowrap;
    }

    .coment-wrap .comments .btn-stage .fa-remove:before {
        font-size: 12px;
        vertical-align: middle;
    }

    .coment-wrap .comments .btn-stage .fa span {
        margin-left: 5px;
    }

    .coment-wrap .comments .btn-stage span {
        margin-right: 4.23%;
    }

    .coment-wrap .comments .btn-stage span:last-child {
        margin-right: 0;
    }

    .coment-wrap .comments .btn-stage .invisible:hover,
    .coment-wrap .comments .btn-stage .reply:hover {
        color: #5ecfba;
    }

    .coment-wrap .comments .detail:hover .invisible {
        visibility: visible !important;
    }

    .coment-wrap .more {
        display: block;
        text-align: center;
        background: #f0f1f3;
        color: #999;
        line-height: 40px;
        margin-top: 15px;
    }

    .coment-wrap .more:hover,
    .coment-wrap .more:focus,
    .coment-wrap .more:active {
        background: #e5e6e7;
        text-decoration: none;
    }

    .privacy-dropdown-menu {
        li {
            display: flex;
            align-items: center;
            .icon {
                font-size: 16px;
                margin-right: 6px;
            }
        }
    }
    .atWrap {
        position: relative;
        border: 1px solid #EBEBEB;

        .evalute-textarea[disabled] {
            background-color: #eee;
        }
        .textarea-disabled {
            background-color: #eee !important;
        }
        
        textarea {
            border: 0 none;
            font-family: Microsoft YaHei, Arial;
            font-size: 14px;
            height: auto;
            padding: 10px;
            width: 100%;
            resize: none;
        }

        .at-tools {
            padding: 5px 20px;
            margin: 0;
            position: relative;
            background-color: #fff;

            .privacy {
                width: 55px;
                margin-left: 15px;
                color: #666666;
                .icon {
                    font-size: 18px;
                    color: #000;
                    margin-right: 6px;
                }
                span {
                    vertical-align: middle;
                }
                &:hover {
                    color: $primary;
                    .icon {
                        color: $primary;
                    }
                }
            }
            /*评论图片*/
            .comment-image {
                cursor: pointer;
                position: relative;
                overflow: hidden;

                a {
                    .icon {
                        font-size: 18px;
                        color: #000;
                    }
                    &:hover {
                        .icon {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }

    .atWrap .at-tools .fa:hover,
    .atWrap .at-tools .pull-right>a:hover,
    .atWrap .at-tools .pull-right .at-list-icon:hover,
    .atWrap .at-tools .comment-image:hover .fa,
    .atWrap .dropdown-menu li:hover a, .atList li:hover a {
        color: $primary;
    }

    .atWrap .at-tools .pull-right>a, .atWrap .at-tools .pull-right .at-list-icon {
        color: #ccc;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        line-height: 18px;
        margin-left: 15px;
        vertical-align: middle;
        width: 18px;
    }

    .atWrap .at-tools .pull-right .at-list-icon {
        // color: #a3afb7;
        color: #333;
        text-decoration: none;

        &:focus {
            border: none;
        }
    }

    .atWrap .at-tools .pull-right>a:hover,
    .atWrap .at-tools .pull-right>a:focus,
    .atWrap .at-tools .pull-right>a:active {
        text-decoration: none;
    }

    /*评论图片*/
    .atWrap .at-tools .comment-image {
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .atWrap .at-tools .comment-image .add-image {
        position: absolute;
        top: 4px;
        left: 15px;
        width: 18px;
        height: 18px;
        opacity: 0;
        filter: alpha(opacity=0);
        line-height: 18px;
        font-size: 0;
        cursor: pointer;
    }

    .comment-image .image-item {
        width: 90px;
        height: 90px;
        float: left;
        margin-top: 10px;
        margin-right: 10px;
        border: 1px solid #ececec;
        cursor: pointer;
    }

    .comment-image.preview .image-item {
        position: relative;
        width: 50px;
        height: 50px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .comment-image.preview .image-item .delete {
        position: absolute;
        display: none;
        top: 2px;
        right: 2px;
        color: #ff7a7a;
    }

    .comment-image.preview .image-item:hover .delete {
        display: block;
    }

    .comment-image.preview .image-item .delete:hover {
        color: #ff493c;
    }

    .comment-image .image-item img {
        width: 100%;
        height: 100%;
    }

    .atWrap {
        min-width: 120px;
    }

    .atList {
        min-width: 240px;
    }

    .atList .search {
        margin: 0;
    }

    .atList input {
        padding: 0 10px;
        height: 26px;
        line-height: 26px;
        font-size: 14px;
        border: 1px solid #e6e6e6;
        margin: 10px;
        width: 90%;
    }

    .atWrap .dropdown-menu ul, .atList ul{
        max-height: 280px;
        overflow-y: auto;
    }

    .atWrap .dropdown-menu li a, .atList li a {
        clear: both;
        color: #828282;
        display: block;
        font-weight: normal;
        line-height: 1.42857;
        padding: 5px 20px;
        white-space: nowrap;
    }

    .atList li a {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .atWrap .dropdown-menu li a .fa {
        display: inline-block;
        width: 18px;
        margin-right: 10px;
    }

    .atWrap .dropdown-menu li a .fa-lock {
        font-size: 15px;
    }

    .team-tip {
        margin: 0 2px;
        font-size: 12px;
        color: #3dbaa3;
        line-height: 20px;
    }
    .bazaSelectMenu .team-tip {
        display: none;
    }

    .bazaSelectMenu .realName {
        color: #666;
    }

    .bazaSelectMenu .nickname {
        color: #999;
    }

    .atList li a:hover,
    .atList li a:focus {
        text-decoration: none;
        background-color: #eef6f9;
    }

    .comment-container .popover {
        min-width: 220px;
    }

    .comment-container .btn-disabled {
        width: 92px;
    }

    .attachment-list .attachment-item {
        display: inline-block;
        padding: 10px 10px 0 0;
        float: left;
    }

    .comment-operation .email-operation {
        float: left;
    }

    .comment-attachment.preview .attachment-item {
        display: inline-block;
        padding: 0 10px 0;
        cursor: pointer;
    }

    .comment-attachment.preview a:hover,
    .comment-attachment.preview a:visited,
    .comment-attachment.preview a:focus {
        outline: none;
        text-decoration: none;
    }

    .comment-attachment.preview .attachment-item .delete:before {
        color: #999;
        font-size: 12px;
        line-height: inherit;
        vertical-align: baseline;
    }

    .comment-attachment.preview .attachment-item .delete {
        margin-left: 10px;
    }

    .comment-attachment.preview .attachment-item .delete:hover:before {
        color: #3dbaa3;
    }

    .comment-attachment.preview .attachment-item:hover a,
    .comment-attachment.preview .attachment-item:hover .delete:before {
        color: #3dbaa3;
    }

    input[type=file]::-webkit-file-upload-button {
        cursor: pointer;
    }

    .comments .avatar {
        width: 50px;
        height: 50px;
    }

    .comments .avatar .corner {
        position: absolute;
        top: -2px;
        right: -7px;
        width: 20px;
        height: 20px;
        z-index: 222;
        color: #fff;
        background-color: #fff;
        border-radius: 20px;
        text-align: center;
        line-height: 18px;
    }

    .comments-wrap .form-horizontal {
        padding: 0;
    }
    .comments-wrap .form-horizontal .form-group {
        margin: 0 0 20px 0;
    }

    .text-right {
        text-align: right;
    }

    .btn-stage .fa:hover {
        color: $primary;
    }

    .delete-comment-dialog {
        padding-top: 20px;

        .modal-box-caption {
            font-size: 18px;
            color: #333;
        }
    }

    .bazaLoding {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.4;
        filter: alpha(opacity=40);
    }
    .bazaLoding i {
        position: absolute;
        top: 49%;
        left: 45%;
        color: #000;
        font-size: 30px;
    }

    .modal-dialog .alert-info {
        padding: 0;
    }
    .modal-dialog .fa-info-circle {
        font-size: 54px;
        color: #3399ff;
        margin-left: 10px;
    }

    .modal-dialog .sub-title {
        font-size: 18px;
        color: #3399ff;
        line-height: 30px;
    }
}
</style>
